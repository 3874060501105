import React from 'react';
import './Styles/TermsOfUse.css';
const TermsOfUse = () => {
    return (
        <div className="terms-of-use-body">
            <h1 className="terms-of-use-title">TERMS OF SERVICE</h1>
            <p className="terms-of-use-text">These Terms of Service (the “Terms”) constitute an agreement governing the use of the Service (defined below)
provided by Mint Setups LLC, (d/b/a Astellyst), to the organization identified in the applicable
Order Form (defined below). The terms of each Order Form are incorporated herein such that the Terms and
each Order Form should be construed as a single agreement. In these Terms, references to “Company,” “we,”
“us,” and “our” refer to Mint Setups LLC; “you,” “your,” and “yours” refer to the organization identified as the
Customer in the applicable Order Form; and references to the “Service” refer to the online software as a service
(“Software”) subscribed to by you and made available by us and supporting services, as described in the Order
Form or otherwise made available by us, including without limitation, and as applicable, any Astellyst products
which provide human resource information system (“HRIS”) services. For the resolution of doubt, “Service”
includes implementation and configuration services, Help Center and Library information and resources,
Customer Care and support services, and Astellyst advisory services or any other consultancy services we may
provide, as applicable. We and you are each sometimes referred to herein as a “party.”</p>
<h2 className="terms-of-use-subtitle">1. ACCESS AND PROVISION OF THE SERVICE</h2>
<p className="terms-of-use-text">1.1 Access and Use. Access to the Software is purchased on a subscription basis by a written order form
describing the Software or any related services (including, but not limited to, Astellyst advisory services or other
consultancy services) to be provided by Astellyst (in each case, an “Order Form”). Subject to these Terms and the
terms of any Order Form signed by both parties, we hereby grant you a non-exclusive, non-sublicensable,
non-transferable (except as provided herein) right, during the Term (defined below), to access and use the
Service solely for your internal business purposes and in accordance with any Service documentation or product
feature descriptions made available to you by us in tangible or electronic format (collectively “Documentation”).</p>
<p className="terms-of-use-text">1.2 Affiliates. Each Order Form and these Terms are entered into for the benefit of the Customer and
its Affiliates. Customer’s Affiliates are entitled to use the Services subject always to their compliance with these
Terms. Customer retains ultimate liability for the acts and omissions of its Affiliates in relation to the Services
and these Terms, including but not limited to payment obligations hereunder and for the distribution of content
submitted or processed through the Customer’s account by its Affiliates, including but not limited to Customer
Content. For the purposes of these Terms, “Affiliate” of a party means any entity that the party directly or
indirectly owns or controls more than fifty percent (50%) of the voting interests of the subject entity or has the
power to direct the management of any policies of the subject entity. Any legal entity will be considered a party’s
Affiliate as long as that interest or control is maintained.</p>
<p className="terms-of-use-subtitle">1.3 Administrative User. At the commencement of the Service, you will enter certain required
information and will identify an administrative contact, including username and password, for your account.
You are required to maintain and promptly update all information provided by you during your registration
process, and any other information you provide to us, so that it remains true, accurate, up-to-date and complete
at all times.</p>
<p className="terms-of-use-text">1.4 Authorized Users. For purposes of these Terms, “Authorized User” means each of your employees,
contractors, and other individuals whom you identify to us by name as being authorized to use the Service on
your behalf. Unless otherwise stated on the Order Form, each Authorized User must be assigned a unique email
address associated with your company’s domain name. A unique password will be provided for each Authorized
User to enable such Authorized User to access the Service on your behalf in accordance with these Terms. We
reserve the right to change passwords in the event of a suspected Security Incident (as defined in the Data
Processing Addendum) or breach of these Terms. Each user’s credentials (email address and password) may not
be shared and may only be used to access the Software during one (1) concurrent login session. You are
responsible for maintaining the confidentiality of all passwords and are responsible for Authorized Users’
compliance with these Terms and any Order Forms. You agree to notify us promptly of any actual or suspected
unauthorized use of your account, or any other breach or suspected breach of these Terms.</p>
<p className="terms-of-use-text">1.5 Technical Support. Subject to the terms hereof, we will use commercially reasonable efforts to make
the Software available to you on a 24 by 7 basis in accordance our maintenance and support practices, as may
be updated by us from time to time.</p>
<p className="terms-of-use-text">1.6 Updates. We reserve the right to update, upgrade or otherwise modify the Software at any time
and with or without notice; provided, however, we will provide advance notice of anticipated downtime when
possible. We may suspend provision of the Software, at any time with reasonable notice (except in emergency
cases relating to security or adverse impacts on the Service), for security or maintenance purposes or as required
by applicable law. We will use commercially reasonable efforts to minimize the duration of any such suspension.</p>
<p className="terms-of-use-text">1.7 Third-Party Services. The Service may allow you to connect to or otherwise interact with one or
more third-party service providers (for example, a human resources information system) for purposes permitted
by the Service. Because we do not control such third-party service providers, access to any such third-parties
through the Service may be implemented, suspended or terminated by us from time to time in our sole
discretion, including as may be necessary for security or maintenance purposes or as required by applicable law.
It is your sole responsibility to enter into and maintain any agreement between you and any such third party for
the provision of their services to you or otherwise, and we are not hereby made a party to such agreement. To
the extent you or your representatives or Authorized Users use the Service to transmit any Customer Content
(defined below) to or from any such third party, you direct and authorize us to provide or receive, respectively,
such Customer Content to or from such third party. To the extent you or your representatives or Authorized
Users use the Service to connect or otherwise interact with any such third party, or have identified or designated
any such third party as your third-party service provider, you authorize us to allow such third party to access
your Customer Content as necessary for us to provide the Service to you. You acknowledge and agree that such
third parties are not our agents, that we are not responsible for their services, compliance, completeness,
accuracy, actions or omissions or for their maintenance or treatment of your Customer Content, that we will
not be liable for and specifically disclaim liability for any damage or loss caused thereby, that access to such
third party via the Service does not imply any endorsement by us, and that any of your Customer Content
submitted to such third parties via the Service will be governed by your agreement (if any) with such third party.
We are not responsible for any disclosure, modification or deletion of Customer Content resulting from access
by such third party.</p>
<p className="terms-of-use-text">1.8 Privacy Compliance and Information Security. Each party’s performance hereunder will be in
compliance with such party’s privacy policy and all applicable privacy laws (including without limitation, as
applicable, United States, Canada and European Economic Area), rules and regulations. Personal information
transmitted, entered or otherwise uploaded by you and your Authorized Users to the Service will be processed
in accordance with our Privacy Policy available at https://astellyst.com/privacypolicy and all applicable laws. We will maintain appropriate
administrative, technical, and procedural safeguards designed to protect the security, confidentiality and
integrity of all Customer Content (“Security Measures”). Our Security Measures will include, but will not be
limited to, measures designed to prevent unauthorized access to or, disclosure of Customer Content (other than
by you or your Authorized Users).</p>
<h2 className="terms-of-use-subtitle">2. OWNERSHIP</h2>
<p className="terms-of-use-text">2.1 Company Properties. As between you and us, the Service and all content therein (excluding
Customer Content, defined below) (collectively, the “Company Properties”), any all and all modifications,
enhancements, upgrades and updates thereto, and all copyrights, trademarks, service marks, trade secrets,
patents and other intellectual property rights therein (registered or unregistered), are the exclusive property of
us and our suppliers. All rights in and to Company Properties not expressly granted to you in these Terms are
reserved by us and our suppliers. For the avoidance of doubt, these Terms do not grant you any right to copy,
transmit, transfer, modify or create derivative works of the Company Properties, or reverse engineer, reverse
compile, reverse assemble or otherwise determine or derive source code of the Company Properties, or any
other right in or to the Company Properties not specifically set forth herein.</p>
<p className="terms-of-use-text">2.2 Customer Content. As between you and us, you are the owner of the content, information, and
other data (including, as applicable, any Confidential or personal information) uploaded by you to the Software,
or otherwise transmitted by you or your representatives or third-party service providers in connection with your
use of the Service (collectively, “Customer Content”). You hereby grant us a non-exclusive, worldwide, royaltyfree and fully paid license, during the Term, to use the Customer Content as necessary for the purpose of
providing the Service to you. Notwithstanding the foregoing license, we have no obligation to process, render,
prepare or produce the Customer Content in any manner or format other than as described in the
Documentation or offered as standard functionality of the Software product(s) you subscribe to. For the
avoidance of doubt, you understand and agree that we have no obligation to provide or support customized
reporting, uploads, or extractions of Customer Content. You are responsible for the accuracy, quality and legality
of Customer Content, the means by which you acquired Customer Content, your use of Customer Content with
the Service and your interoperation of any Customer Content with the Service.</p>
<p className="terms-of-use-text">2.3 Anonymized, Aggregated Data. We automatically collect certain usage data and information
generated by, submitted, or uploaded to the Software relating to certain user actions taken in the platform,
such as the number of times users access or use certain Software features such as the number of reviews or
goals completed by an Authorized User. We process such data for the purpose of generating anonymized,
aggregated statistical data. Such anonymized, aggregated statistical data is used for benchmarking purposes,
as well as to maintain, secure, and improve our products and services. Such data does not contain any text
narrative which is or was part of Customer Content, Customer Confidential Information, or data or information
that can be used to identify Customer, an Authorized User, or any individual.</p>
<p className="terms-of-use-text">2.4 License to Use Feedback. You grant us a worldwide, perpetual, irrevocable, royalty-free license to
use and incorporate into our Software and Service any suggestion, enhancement request, recommendation,
correction or other feedback provided by you or your Authorized Users relating to the operation of the Company
or the Service.</p>
<h2 className="terms-of-use-subtitle">3. TERM AND TERMINATION</h2>
<p className="terms-of-use-text">3.1 Term. The duration of the Software subscription term of each Order Form is the Service Term. The
“Effective Date” means the Effective Date specified on the Order Form or, if no date is specified, the date the
Order Form was entered. The “Initial Subscription Term” means the Initial Service Term of the Software
subscription specified in the Order Form or the period from the Effective Date through the expiration of the
Initial Service Term. Unless sooner terminated as provided in this Section 3, these Terms will be effective
beginning on the Effective Date and remain in effect for the combined duration of the Initial Subscription Term
and any subsequent Service Term (each a “Renewal Term”), such that these Terms are coterminous with the
natural expiration or earlier termination of the latest Order Form. The Initial Subscription Term collectively with
each Renewal Term is the “Term”. Notwithstanding the foregoing, the maximum term of use for any
complimentary Software product is the earlier of the renewal, expiration, or termination of a paid Software
product included on the Order Form, provided that use of a complimentary (unpaid) Software product or Service
may be terminated by us at any time and for any reason.</p>
<p className="terms-of-use-text">3.2 Renewal. Unless otherwise stated on the applicable Order Form, upon the expiration of each Order
Form, each Software Order Form will automatically renew for a consecutive Renewal Term of the same duration
as the Initial Subscription Term. Either party may terminate such Order Form by giving the other party written
notice of such termination at least thirty (30) days prior to the end of the then-current Initial Subscription Term
or Renewal Term, in which case such termination will be effective at the end of such Initial Subscription Term
or Renewal Term. In addition to the foregoing, upon Renewal under this Section 3.2 any complimentary
Software product on an applicable Order Form will also renew at the then current list price for such Software
unless either party provides its notice of intent to terminate such Software subscription in accordance with this
Section 3.2.</p>
<p className="terms-of-use-text">3.3 Termination for Breach. Either party may terminate these Terms or any Order Form if the other
party materially breaches these Terms or Order Form and fails to cure such breach within thirty (30) days after
it receives written notice of such breach. A material breach includes, without limitation, a failure to make a full
and timely payment (not disputed in good faith), or a commission of any of the acts contemplated in Section
4.1. If you terminate these Terms or an Order Form due to Astellyst’s breach, any related prepaid Fees for Services
not yet provided will be refunded to you.</p>
<p className="terms-of-use-text">3.4 Effect of Termination. Sections 2 (Ownership), 3 (Term and Termination), 5 (Confidentiality), 6
(Indemnification), 9 (Limitation of Liability), 10 (Dispute Resolution), and 11 (General) will survive any
termination of these Terms. Termination of these Terms with respect to one Order Form does not terminate
these Terms with respect to any other Order Form.</p>
<p className="terms-of-use-text">3.5 Return and Deletion of Customer Content. You may request the return of your respective Customer
Content at any time (to the extent such data has not been deleted by you or by us in accordance with our data
deletion policy, and subject to the limitations described in Section 2.2). A downloadable file will be made
available to you upon request or upon the termination or expiration of your subscription to the Service. After
effective termination or expiration of your subscription to the Software, Customer Content is retained in inactive
status for six months, after which it is securely and permanently deleted. In order to comply with applicable
data privacy laws, we reserve the right, in our sole discretion, to reduce the number of days we retain such data
after termination or expiration of your subscription to the Service. Further, we may retain, copies of certain
Customer Content, such as that related to taxes, payroll, or payroll related services, to the extent and for the
period required by any applicable law or regulation.</p>
<h2 className="terms-of-use-subtitle">4. CUSTOMER RESPONSIBILITIES</h2>
<p className="terms-of-use-text">4.1 Use Restrictions. You will not, and will not permit any Authorized User to: (a) permit any person or
entity to access the Service, other than Authorized Users to the extent authorized under these Terms; (b) use
the Service except in accordance with these Terms, the Documentation and applicable law; (c) modify, adapt,
alter, or copy the Service; (d) sell, resell, license, distribute, rent or lease the Service, or include the Service in a
service bureau or outsourcing offering; (e) use the Service to store or transmit any “viruses,” “worms,” “Trojan
horses,” “e-mail bombs,” “cancelbots” or other harmful computer code, files, scripts, agents or programs; (f)
use the Service to store or transmit deceptive, infringing, defamatory or otherwise unlawful or tortious
materials, or to store or transmit material in violation of (i) third-party privacy or other rights, or (ii)
confidentiality obligations to third parties; (g) access the Service in order to build a competitive product or
service; (h) interfere with or disrupt the integrity or performance of the Company Properties; or (i) attempt to
gain unauthorized access to the Company Properties.</p>
<p className="terms-of-use-text">4.2 Required Equipment. You are responsible for obtaining and maintaining any equipment and
ancillary services needed to connect to the Internet in order to access or otherwise use the Service, including,
without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the
like (collectively, “Equipment”), excluding the equipment and technology necessary for us to host and make the
Service available to you. You are responsible for maintaining the security of the Equipment.</p>
<p className="terms-of-use-text">4.3 Compliance with Law. You understand and agree that at all times during the Term you will comply
with all applicable laws and regulations in connection with your own activities performed under these Terms or
otherwise related to your use of the Software or Service. You further understand and agree that it may be
possible for you to configure or use certain products of the Software or Service in manners that do not comply
with applicable laws or regulations, and at all times during the Term it is your sole responsibility to ensure that
your use and your Authorized Users’ use of the Software and Service is in accordance and compliance with
applicable laws and regulations, and that in no event will we be responsible or liable for your failure to comply
with an applicable law or regulation in connection with or related to your use of the Software or Service. </p>
<p className="terms-of-use-text">4.4 Employment Decisions. You acknowledge and agree that we exercise no control over any
employment or human resource practices or actions you implement or execute using the Software or Service,
nor any your decisions or actions with regard to employment, promotion, advancement, termination,
notification, or compensation of any employee, contractor, or Authorized User of the Software or Service.
Further, you acknowledge and agree that we do not have a direct relationship with your employees, contractors,
or Authorized Users, and that as between you and us, you are solely responsible for all communication and data
collection with and from your employees, contractors, and Authorized Users.
In addition to the foregoing, you acknowledge, understand, and agree that at all times during the Term with
regard to any employment offers or total rewards summaries you provide to any candidates, employees,
contractors, or other Authorized Users, you are solely responsible for ensuring that the appropriate and
necessary information and terms for each such employment offer and total rewards summary you provide are
complete and accurate. If applicable with respect to the product you use, any total rewards summaries provided
in the Software or Service are solely a hypothetical example, are not promises of any compensation or type of
compensation and are subject to change. Any such total rewards summaries and information provided in
association therewith are intended for general informational purposes only and are not to be construed as legal,
financial, or tax advice. At all times during the Term, you are responsible for fulfilling any offers of employment,
compensation, or rewards that you make to your employees, contractors, applicants, or other Authorized Users.
WE MAKE NO REPRESENTATIONS OR WARRANTIES AND TAKE NO RESPONSIBILITY FOR ANY EMPLOYMENT
OFFERS OR TOTAL REWARDS SUMMARIES (OR ANY INFORMATION SUBMITTED BY YOU IN CONNECTION
THEREWITH) THAT ARE CREATED, VIEWED, REJECTED OR ACCEPTED IN THE SOFTWARE OR SERVICES.</p>
<p className="terms-of-use-text">4.5 Export Compliance. You represent that you are not named on any United States government
denied-party list. You agree not to export, re-export, or transfer, directly or indirectly, any technical data
acquired from us, or any products utilizing such data, in violation of United States export laws or regulations.
You will not permit any Authorized User to access or use the Software (i) if such person is a resident of a country
embargoed by the United States, (ii) if such person is a person or entity blocked or denied by the United States
government, or (iii) otherwise in violation of United States export laws or regulations.</p>
<h2 className="terms-of-use-subtitle">5. CONFIDENTIALITY</h2>
<p className="terms-of-use-text">5.1 Definition. Each party may have access to the other party’s information, which will be deemed
“Confidential Information” if identified as such by the disclosing party or if the information by its nature is
normally and reasonably considered confidential, such as information regarding product, methodology,
research, customers, business partners, business plans and any information that provides a competitive
advantage. Your Confidential Information includes Customer Content; Confidential Information of the Company
includes all non-public aspects of the Service and these Terms and all Order Forms (including pricing).</p>
<p className="terms-of-use-text">5.2 Standard of Care. The receiving party will use the same degree of care as it uses to protect its own
Confidential Information of a like nature, but not less than a reasonable degree of care, to (a) prevent use or
copying of the disclosing party’s Confidential Information for any purpose other than to perform its obligations
or exercise its rights as provided under these Terms, and (b) prevent disclosure of the disclosing party’s
Confidential Information other than to its employees (or agents bound by similar confidentiality obligations)
with a need to access the Confidential Information for purposes consistent with these Terms. Confidential
Information will remain the property of the disclosing party and will be returned or (along with all copies)
destroyed upon request, at which time the receiving party will provide to the disclosing party a written
certification of such return or destruction. Without limiting any other provision of these Terms, with respect to
its obligations under this Section 5, the receiving party will be responsible for the acts and omissions of its
employees, contractors and agents to the same extent as if those acts and omissions were those of the receiving
party. The parties acknowledge, intend, and agree that this Section 5 and the obligations set forth herein
supersede any and all prior confidentiality, non-disclosure, or similar agreements between the parties.</p>
<p className="terms-of-use-text">5.3 Exceptions. Information will not be deemed Confidential Information hereunder if it: (i) is
information that becomes generally known to the public through no fault of the receiving party, its affiliates or
their agents or representatives; (ii) is or becomes known to the receiving party without restriction from a third
party other than as a result of breach of contract or wrongful or tortious act; or (iii) is independently developed
by the receiving party without reference to or reliance on the disclosing party’s Confidential Information.
Confidential Information may be disclosed to the extent required by applicable law, regulation, or court order
or judicial decree, provided the disclosing party is given reasonable advance notice of such disclosure.</p>
<h2 className="terms-of-use-subtitle">6. PAYMENT OF FEES</h2>
<p className="terms-of-use-text">6.1 Fees. You will pay us the fees set forth in the applicable Order Form (other than fees disputed in
good faith) in accordance with the terms therein (the “Fees”). All Fees are nonrefundable except as expressly
provided herein. Any Fees per Authorized User will apply with respect to the initial number of Authorized Users
identified in the Order Form, plus any additional Authorized Users added during a Service Term. Fees for
additional Authorized Users will be prorated through the end of the current Service Term and will be invoiced
to you in the month after the additional Authorized Users are granted access to the Software. Fees for additional
Authorized Users will be determined based on product specific user counts as outlined in the Order Form. With
respect to any Order Form, we may increase the Fees in effect at the start of any Renewal Term by giving you
at least forty-five (45) days advance written notice of such increase.</p>
<p className="terms-of-use-text">6.2 Payment. If you pay your Fees by credit card, you hereby authorize us and our third-party payment
service providers to collect all due and payable Fees using the credit card or other payment method you provide
to us, and you must keep the payment method and other billing information you provide to us current at all
times; any change in such method or information will not affect charges we submit via the prior payment
method and information before we reasonably could act on the change. All invoices, fees and any other
applicable charges are due and payable to us within thirty (30) days after the date of our applicable invoice. All
Fees and other amounts are payable in United States Dollars, unless otherwise set forth in the Order Form.</p>
<p className="terms-of-use-text">6.3 Late Payments. If you fail to make payments (not disputed in good faith) when due under these
Terms, and after continued non-payment for a period of fifteen (15) days after we provide you with written
notice and an opportunity to cure, we may suspend provision of the Service until payment is received and may
charge you the overdue amount together with our costs incurred in collecting such payment. As a nonexclusive
remedy, we are entitled to withhold performance and suspend provision of the Service until all undisputed
amounts due are paid in full. In the unlikely event our collections efforts are unsuccessful, and we are required
to obtain a third party or parties to collect Fees not reasonably disputed in good faith, you agree to incur the
reasonable costs associated with such third-party collections efforts.</p>
<p className="terms-of-use-text">6.4 Taxes. Fees do not include any taxes, levies, duties, export or import fees, or other governmental
assessments of any nature, including but not limited to value-added sales, use or withholding taxes, imposed or
assessed by any jurisdiction (collectively, “Taxes”). You are responsible for the payment of all applicable Taxes
(other than Taxes assessable against us based on our income, property, franchise or employment) associated
with your subscription to the Service. You agree to cooperate with us and provide us with timely and accurate
information as may be required for the calculation and withholding of applicable Taxes. If we have a legal
obligation to collect and remit Taxes for which you are responsible, we will invoice you and you will pay us that
amount unless you provide us with a valid tax exemption certificate authorized by the appropriate taxing
authority.</p>
<h2 className="terms-of-use-subtitle">7. WARRANTIES AND DISCLAIMER</h2>
<p className="terms-of-use-text">7.1 Each party represents that it has validly entered into these Terms and any resulting agreement and
is not subject to any agreements that conflict with the undertakings provided hereunder.</p>
<p className="terms-of-use-text">7.2 We hereby warrant that during the Term:
a. we own, or otherwise properly license, the Software, and the Service, and have the full power and
authority required to use, publish, transfer, and/or license any and all rights and interests in and to
the Software and Service to you;
b. we shall provide and perform all Software and Service in a professional and workmanlike manner
in accordance with prevailing industry standard and practices for similar enterprise software as a
service; and
c. all Software, Service, Company Properties, and any other content provided to you under these
Terms shall comply with applicable law.</p>
<p className="terms-of-use-text">7.3 EXCEPT AS OTHERWISE EXPRESSLY SET FORTH HEREIN, THE SERVICE, SOFTWARE, AND ALL
COMPANY PROPERTIES ARE PROVIDED “AS IS,” AND NEITHER PARTY MAKES AND WE SPECIFICALLY DISCLAIM
ALL OTHER WARRANTIES OF ANY KIND, WHETHER EXPRESSED, IMPLIED OR STATUTORY, INCLUDING BUT NOT
LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE
AND NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. WE DO NOT WARRANT
THAT THE SERVICE, SOFTWARE, OR COMPANY PROPERTIES WILL BE ERROR-FREE OR UNINTERRUPTED. YOU
AGREE THAT WE ARE NOT LIABLE FOR ANY ERRORS OR INACCURACIES IN DATA OR CUSTOMER CONTENT
PROVIDED BY YOU OR ANY OF YOUR AUTHORIZED USERS IN CONNECTION WITH YOUR USE OF THE SERVICE OR
SOFTWARE, INCLUDING ANY REPORTS, ANALYSIS, OR ANY OTHER OUTPUTS PRODUCED OR DERIVED
THEREFROM. YOU UNDERSTAND AND AGREE THAT WE ARE NOT RENDERING ANY LEGAL, TAX, BENEFIT,
ACCOUNTING, OR INVESTMENT ADVICE IN CONNECTION WITH PROVIDING THE SERVICE AND SOFTWARE AND
WE WILL NOT BE DEEMED TO BE A FIDUCIARY OR THE EMPLOYER OR JOINT EMPLOYER OF YOUR AUTHORIZED
USERS, EMPLOYEES, OR CONTRACTORS FOR ANY PURPOSE. YOU FURTHER ACKNOWLEDGE AND AGREE THAT
THE SOFTWARE AND SERVICE ARE NOT INTENDED FOR THE USE, STORAGE, COLLECTION, OR MAINTENANCE OF
PROTECTED HEALTH INFORMATION (“PHI”) AS DEFINED BY THE HEALTH INSURANCE PORTABILITY AND
AFFORDABILITY ACT (“HIPAA”), THAT WE ARE NOT A COVERED ENTITY OR BUSINESS ASSOCIATE AS DEFINED
UNDER HIPAA, AND THAT THE CUSTOMER CONTENT WILL BE FREE OF ANY PHI YOU WILL NOT UPLOAD OR
STORE PHI IN THE SOFTWARE OR SERVICE. FOR THE AVOIDANCE OF DOUBT, WE DO NOT PROVIDE CUSTOMERS
WITH AND WILL NOT PROVIDE YOU WITH LEGAL ADVICE REGARDING COMPLIANCE, DATA PRIVACY, OR ANY
OTHER APPLICABLE LAWS OR REGULATIONS, AND ANY STATEMENTS MADE BY US IN RELATION TO YOUR USE
OF THE SOFTWARE OR SERVICE DOES NOT AND WILL NOT CONSTITUTE LEGAL ADVICE. ACCORDINGLY, YOU
AGREE THAT WE ARE NOT LIABLE FOR ANY CLAIMS ARISING FROM ANY ADVICE PROVIDED BY US IN
CONNECTION WITH THE SERVICES. IMPLEMENTATION AND ADVISORY SERVICES ARE PROVIDED AT YOUR OWN
RISK. FURTHER, ANY CONFLICTING TERM HEREIN NOTWITHSTANDING, WHERE APPLICABLE, COMPLIMENTARY
SERVICES ARE ACCEPTED BY CUSTOMER AS-IS AND ARE NOT SUBJECT TO ANY SERVICE LEVEL AGREEMENT OR
PRODUCT PERFORMANCE WARRANTIES.</p>
<p className="terms-of-use-text">7.4 You hereby warrant that:
a. you own, or otherwise properly license, the Customer Content;
b. you have the full power and authority to make the license grants hereunder;
c. your use of the Software and Service and any Customer Content disclosed to us or uploaded to the
Software under these Terms will comply with any applicable law; and
d. any Customer Content provided to us in relation to your use of the Service or Software is accurate
and complete to the best of your knowledge.</p>
<h2 className="terms-of-use-subtitle">8. INDEMNIFICATION</h2>
<p className="terms-of-use-text">8.1 By Us. We will defend, indemnify, and hold harmless you and your officers, directors, employees
and agents from and against all claims, damages, losses and external expenses (including reasonable attorneys’
fees), except where caused in whole or in part by your use of the Service in violation of these Terms, arising out
of any claim by any third party to the extent such claim alleges that the Service or Software (in each case as
made available to you for use in accordance with these Terms) infringes or misappropriates any such third
party’s intellectual property rights, under the Uniform Trade Secrets Act, U.S. patent, copyright, trademark or
other applicable intellectual property law. Notwithstanding the foregoing, we will have no obligation under this
paragraph and will not otherwise be liable to the extent the claim is based on: (i) use of the Service or Software
in combination with any hardware, software, data, content, or other component not provided by us (other than
Equipment required for use of the Service or Software as permitted hereunder); (ii) Customer Content; or (iii)
use of the Service or Software other than in accordance with these Terms and the Documentation.</p>
<p className="terms-of-use-text">8.2 By You. You will defend, indemnify and hold harmless us and our officers, directors, employees
and agents from and against all claims, damages, losses and external expenses (including reasonable attorneys’
fees) arising out of any claim by any third party to the extent such claim is based on or related to: your use of
the Software or Service in violation of these Terms, or other than in accordance with any advice, training,
materials or resources, or implementation services provided by us in connection with the Service; your
Customer Content; any employment decision, action, or inaction you take arising from or related to information
available through, or your use of, the Software or Service, or arising from or related to the inaccuracy or
incompleteness of any information you make available to your employees, contractors, candidates, or
Authorized Users by or through the Software or Service; or your actual or alleged violation of applicable law or
the rights of any third party, including without limitation any applicable privacy right or any right provided by
an applicable labor or employment law or regulation.</p>
<p className="terms-of-use-text">8.3 Procedures. The person or entity seeking indemnification hereunder (the “Indemnified Person”)
will provide detailed written notice to the indemnifying party promptly after learning of the claim, and the
indemnifying party will not be obligated to indemnify only to the extent it is materially prejudiced by any delay
in such notice. The indemnifying party will have the right to assume control of the defense and settlement of
the claim (provided that the prior, written agreement of the Indemnified Person will be required in order to
enter into any settlement or compromise that does not include a full release of the Indemnified Person or that
requires any payment or admission of wrongdoing by the Indemnified Person), in which case the Indemnified
Person (i) will provide reasonable assistance at the indemnifying party’s reasonable expense and (ii) may employ
counsel at its own expense. If we believe our intellectual property is or may become the subject of a claim of
infringement or misappropriation, we may, at our option and expense, procure for the right to use the
intellectual property, or modify or replace the intellectual property to make it non-infringing and functionally
equivalent. If we reasonably conclude that neither of these alternatives is reasonably available, we may require
the return or destruction of its intellectual property upon written request and the termination of these Terms
to the extent performance is based upon or involves the use of such intellectual property, in which case you
would be entitled to a refund of any prepaid fees for the period after termination.</p>
<h2 className="terms-of-use-subtitle">9. LIMITATION OF LIABILITY</h2>
<p className="terms-of-use-text">EXCEPT FOR THE INDEMNIFICATION OBLIGATIONS HEREIN, TO THE MAXIMUM EXTENT PERMITTED BY
APPLICABLE LAW, NEITHER PARTY WILL BE LIABLE FOR ANY SPECIAL, INDIRECT, EXEMPLARY, PUNITIVE,
INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING BUT NOT LIMITED TO LOSS OF DATA, USE OR PROFIT,
PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR BUSINESS INTERRUPTION) ARISING OUT OF OR
RELATING TO THE SERVICE OR THESE TERMS, REGARDLESS OF THE CAUSE OF ACTION OR THE THEORY OF
LIABILITY, WHETHER IN TORT, CONTRACT, OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
DAMAGES.
EACH PARTY’S MAXIMUM AGGREGATE LIABILITY ARISING OUT OF OR RELATING TO THE SERVICE OR THESE
TERMS WILL NOT EXCEED AN AMOUNT EQUAL TO THE FEES PAID OR PAYABLE BY YOU DURING THE TWELVE
(12) MONTHS IMMEDIATELY PRIOR TO THE INITIAL EVENT CAUSING OR RESULTING IN SUCH LIABILITY.
REGULATIONS AND JUDICIAL PRECEDENT IN CERTAIN STATES LIMIT THE MAXIMUM AMOUNT THAT DAMAGES
CAN BE LIMITED BY OPERATION OF CONTRACT. IF UNDER SUCH CIRCUMSTANCES, THE FOREGOING LIMITATION
OF LIABILITY IS DEEMED INEFFECTIVE AS A MATTER OF LAW, IT SHOULD INSTEAD BE CONSTRUED TO LIMIT
LIABILITY TO THE MINIMUM AMOUNT THAT WOULD BE CONSISTENT WITH SUCH LAWS OR PRECEDENT WHILE
STILL GIVING EFFECT TO THE INTENTION OF THIS CLAUSE. EACH PARTY ACKNOWLEDGES THAT IT HAS ENTERED
INTO THIS AGREEMENT IN RELIANCE UPON THE LIMITATIONS OF LIABILITY SET FORTH HEREIN, AND THAT ALL
SUCH LIMITATIONS FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN THE PARTIES.</p>
<h2 className="terms-of-use-subtitle">10. DISPUTE RESOLUTION</h2>
<p className="terms-of-use-text">10.1 Arbitration. The parties will attempt in good faith to resolve any dispute arising out of or relating
to the Service or these Terms by negotiation. Any such dispute not so resolved will be resolved by binding
arbitration administered by the American Arbitration Association under its Commercial Arbitration Rules, or
JAMS International Arbitration Rules if Customer is registered in any country outside of the United States of
America, (except to the extent that such rules are inconsistent with the provisions set forth herein). Such dispute
will be heard in King County, Washington, by one neutral arbitrator. The arbitrator(s) will have the
authority to award compensatory damages only, and will not have the authority to consolidate actions or claims.
The award rendered by the arbitrator(s) will be final and binding, and judgment upon such award may be
entered in any court having jurisdiction thereof. Notwithstanding any other provision herein, any demand by a
party for arbitration must be made in writing to the other party within the period prescribed by the state or
federal statute of limitations applicable to the claim upon which the demand is based. If demand is made after
such period, the claim will be void and deemed waived. The existence, conduct and content of such arbitration
will be kept confidential, and neither party will disclose the same to any person other than its attorneys,
auditors, financial advisors, assignees or potential assignees, except as may be required by law or governmental
authority or for financial reporting purposes.</p>
<p className="terms-of-use-text">10.2 Limitations Period. Regardless of any statute or law to the contrary, any claim or cause of action
arising out of or related to these Terms or the use of the Service must be filed or otherwise commenced within
two (2) years after such claim or cause of action arose or be forever barred.</p>
<p className="terms-of-use-text">10.3 Injunctive Relief. Notwithstanding any other provision hereof, either party may seek to enforce
its rights hereunder with respect to the protection of its Confidential Information or intellectual property
through temporary or permanent injunctive relief, which will be in addition to any other available relief and
which will not require a bond or security. Each party acknowledges that any breach by a party of its obligations
hereunder with respect to the Confidential Information or intellectual property rights of the other party might
constitute immediate, irreparable harm to such other party for which monetary damages would be an
inadequate remedy.</p>
<h2 className="terms-of-use-subtitle">11. GENERAL</h2>
<p className="terms-of-use-text">11.1 Notice. All notices and other communications given or made pursuant to these Terms will be in
writing and will be deemed effectively given upon the earliest of (i) actual receipt, (ii) personal delivery to the
recipient, or (iii) any of the following if addressed to the recipient as set forth below: (a) when sent, if sent by
electronic mail during normal business hours of the recipient, and if not sent during normal business hours, then
on the recipient’s next business day, (b) five (5) days after having been sent by registered or certified mail, return
receipt requested, postage prepaid, or (c) one (1) business day after deposit with a nationally recognized
overnight courier, freight prepaid, specifying next business day delivery, with written verification of receipt. For
purposes hereof, each recipient’s address and email address will be as set forth in the Order Form (or, with
respect to your addresses, as provided by you during registration or onboarding with the Service), as such
contact information may be subsequently modified by the recipient by written notice given in accordance with
this paragraph. We may provide notices to you using the electronic messaging system included in the Service, in
which case such notice will be deemed given when sent, if sent during your normal business hours, and if not
sent during such normal business hours, then on your next business day.</p>
<p className="terms-of-use-text">11.2 Assignment. Neither party may assign or otherwise transfer these Terms or any rights or obligations
hereunder without the written consent of the other party, except that either party may, without such consent,
assign or transfer these Terms to an Affiliate or a purchaser of all or substantially all of its assets or to a successor
organization by merger, consolidation, change of control, conversion or otherwise. These Terms are binding
upon and inure to the benefit of the parties and their respective successors and permitted assigns.</p>
<p className="terms-of-use-text">11.3 Force Majeure. Neither party will be liable for any delay or failure to perform its obligations
hereunder (other than any obligation to make a payment) resulting from any cause beyond such party’s
reasonable control, including pandemic, weather, fire, floods, labor disputes, riots or civil disturbances, acts of
government, and acts of war or terrorism, provided that, in every case, the delay or failure to perform is beyond
the control and without the fault or negligence of the party claiming excusable delay and that such party cures
the breach as soon as possible after the occurrence of the unforeseen event.</p>
<p className="terms-of-use-text">11.4 Governing Law. These Terms and any action related thereto will be governed and interpreted by
and under the laws of the State of Washington, consistent with the Federal Arbitration Act, without giving effect
to any principles that provide for the application of the law of another jurisdiction. In either case, The United
Nations Convention on Contracts for the International Sale of Goods does not apply to these Terms.</p>
<p className="terms-of-use-text">11.5 Public Announcements. You grant us the right to use your name, logo, trademarks and/or trade
names in press releases, webpages, product brochures and financial reports indicating that you are a customer
of ours. All other public statements or releases will require the mutual consent of the parties.</p>
<p className="terms-of-use-text">11.6 Relationship of Parties. Neither these Terms nor the cooperation of the parties contemplated
under these Terms will be deemed or construed to create any partnership, joint venture or agency relationship
between the parties. Neither party is, nor will either party hold itself out to be, vested with any power or right
to bind the other party contractually or act on behalf of the other party as a broker, agent or otherwise.</p>
<p className="terms-of-use-text">11.7 Waiver. Any provision of these Terms may be waived only in a writing signed by the party to be
bound thereby. Any waiver or failure to enforce any provision of these Terms on one occasion will not be deemed
a waiver of any other provision or of such provision on any other occasion.</p>
<p className="terms-of-use-text">11.8 Severability. If any provision of these Terms is, for any reason, held to be invalid, illegal, or
unenforceable, the other provisions of these Terms will remain enforceable, and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.</p>
<p className="terms-of-use-text">11.9 Entire Agreement These Terms (including any Order Form incorporated herein) and its exhibits and
attachments constitute the entire agreement of the parties relating to the subject matter hereof and supersedes
all prior or contemporaneous communications, understandings and agreements, oral or written, relating to such
subject matter.</p>
<p className="terms-of-use-text">11.10 Construction. These Terms will be construed as a whole, according to its fair meaning, and not in
favor of or against any party. Headings are used for reference purposes only and should be ignored in the
interpretation of these Terms. All pronouns will be interpreted to refer to the masculine, feminine or neutral
    will be deemed to be followed by the words “without limitation.”</p>
<p className="terms-of-use-text">11.11 Amendments. No amendment of these Terms will be effective unless contained in a written
agreement signed by both parties that specifically purports to amend these Terms. </p>


</div>
 );
};

export default TermsOfUse;