import React from 'react';
import './Styles/Careers.css';

const CareersPage = () => {
    const jobPostings = []; // Replace with actual job postings data

    return (
        <div className="careers-body">
            <h1 className="careers-title">Careers</h1>
            <div className="careers-container">
            <div className="careers-section">
                <h2 className="careers-subtitle">Open Positions</h2>
                <p className="careers-text">
                    We are always looking for talented individuals to join our team. Please check back soon for new openings.
                </p>
                <select className="careers-dropdown" disabled={jobPostings.length === 0}>
                    {jobPostings.length > 0 ? (
                        <>
                            <option value="">Select a job posting</option>
                            {jobPostings.map((job, index) => (
                                <option key={index} value={job.id}>{job.title}</option>
                            ))}
                        </>
                    ) : (
                        <option value="">No openings</option>
                    )}
                </select>
                <p className="careers-text">
                    Think you can help us change the way we work? Please email your resume to <a href="mailto:careers@astellyst.com" className="careers-link">careers@astellyst.com.</a>
                </p>
            </div>
            </div>
        </div>
    );
};

export default CareersPage;