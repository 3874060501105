import React, { useState } from 'react';
import '../Styles/ManagerPortal.css'; // Updated CSS import
import { Link } from 'react-router-dom';

const ManagerPortal = () => {
    const [activeTab, setActiveTab] = useState('set-goals');

    const renderContent = () => {
        switch (activeTab) {
            case 'set-goals':
                return (
                    <div>
                        <h2>Set Team Goals</h2>
                        <p>Define goals for your team to achieve.</p>
                        <div className='goal-setting-button-container'>
                            <Link to="/ManagerMaster">
                                <button className='goal-setting-button'>Set Team Goals</button>
                            </Link>
                            <Link to="/ManagerMaster?step=2">
                                <button className='goal-setting-button'>Review Goals</button>
                            </Link>
                        </div>
                    </div>
                );
            case 'your-team':
                return (
                    <div>
                        <h2>Your Team</h2>
                        <p>View and manage your team members.</p>
                        <Link to="/TeamMembers">
                            <button className='goal-setting-button'>View Team</button>
                        </Link>
                    </div>
                );
            case 'set-your-goals':
                return (
                    <div>
                        <h2>Set Your Goals</h2>
                        <p>Establish your personal goals.</p>
                        <Link to="/EmployeeMaster">
                            <button className='goal-setting-button'>Set Your Goals</button>
                        </Link>
                    </div>
                );
            case 'your-progress':
                return (
                    <div>
                        <h2>Your Progress</h2>
                        <p>View your progress.</p>
                        <Link to="/ProgressPage">
                            <button className='goal-setting-button'>View Progress</button>
                        </Link>
                    </div>
                );
            default:
                return <div>Please select a tab.</div>;
        }
    };

    return (
        <div className="manager-portal"> {/* Updated class name */}
            <div className="manager-portal-sidebar">
                <button className="manager-portal-sidebar-button" onClick={() => setActiveTab('set-goals')}>Set Team Goals</button>
                <button className="manager-portal-sidebar-button" onClick={() => setActiveTab('your-team')}>Your Team</button>
                <button className="manager-portal-sidebar-button" onClick={() => setActiveTab('set-your-goals')}>Set Your Goals</button>
                <button className="manager-portal-sidebar-button" onClick={() => setActiveTab('your-progress')}>Your Progress</button>
            </div>
            <div className="manager-portal-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default ManagerPortal;