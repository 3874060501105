import React, { useState, useEffect } from 'react';

function EmployeeStep3({ employeeGoals, prevStep, saveForLater, setEmployeeGoals, submitData }) {
    const [isEditingGoals, setIsEditingGoals] = useState(false);
    const [editedGoals, setEditedGoals] = useState([]);

    useEffect(() => {
        if (employeeGoals.length === 0 || employeeGoals.every(goal => !goal.text.trim())) {
            setEditedGoals(['']);
        } else {
            setEditedGoals(employeeGoals.map(goal => goal.text));
        }
    }, [employeeGoals]);

    const toggleEditGoals = () => {
        if (isEditingGoals) {
            const nonEmptyGoals = editedGoals.filter(goalText => goalText.trim() !== '');
            setEditedGoals(nonEmptyGoals.length > 0 ? nonEmptyGoals : ['']);
            setEmployeeGoals(nonEmptyGoals.map(goalText => ({ text: goalText, confirmed: goalText !== '' })));
        }
        setIsEditingGoals(!isEditingGoals);
    };

    const addGoal = () => {
        if (editedGoals.length < 5) {
            setEditedGoals([...editedGoals, '']);
        }
    };

    const deleteGoal = (index) => {
        if (editedGoals.length > 1) {
            const newGoals = [...editedGoals];
            newGoals.splice(index, 1);
            setEditedGoals(newGoals);
        }
    };

    return (
        <div className='employee-goal-set-container'>
            <h2>Review Your Personal Goals</h2>
            <div className='employee-goal-set-review-container'>
                <h3>Personal Goals</h3>
                <div className='employee-goal-set-flex-container'>
                    {isEditingGoals ? (
                        <ul>
                            {editedGoals.map((goal, index) => (
                                <li className='employee-goal-set-review-list-edit' key={index}>
                                    <textarea
                                        className='employee-goal-set-input'
                                        value={goal}
                                        onChange={(e) => {
                                            const newGoals = [...editedGoals];
                                            newGoals[index] = e.target.value;
                                            setEditedGoals(newGoals);
                                        }}
                                    />
                                    {editedGoals.length > 1 && (
                                        <button
                                            onClick={() => deleteGoal(index)}
                                            className='employee-goal-delete-button'
                                        >
                                            Delete
                                        </button>
                                    )}
                                </li>
                            ))}
                            <div className='employee-goal-set-button-review-wrapper'>
                                {editedGoals.length < 5 && (
                                    <button onClick={addGoal} className='employee-goal-set-add-button'>Add Goal</button>
                                )}
                                <button onClick={toggleEditGoals} className='employee-goal-set-button'>Done Editing</button>
                            </div>
                        </ul>
                    ) : (
                        <ul className='employee-goal-set-review-list-text'>
                            {employeeGoals.length > 0 && employeeGoals.filter(goal => goal.confirmed && goal.text).length > 0 ? (
                                employeeGoals.filter(goal => goal.confirmed && goal.text).map((goal, index) => (
                                    <li className='employee-goal-set-list-item' key={index}>{goal.text}</li>
                                ))
                            ) : (
                                <p className='employee-goal-set-review-text'>No personal goals provided.</p>
                            )}
                        </ul>
                    )}
                    {!isEditingGoals && (
                        <button onClick={toggleEditGoals} className='employee-goal-set-button'>Edit</button>
                    )}
                </div>
            </div>
            <div className='employee-goal-set-button-container'>
                <button onClick={prevStep} className='employee-goal-set-button'>Back</button>
                <button onClick={saveForLater} className='employee-goal-set-button'>Save for Later</button>
                <button onClick={submitData} className='employee-goal-set-submit-button'>Submit</button>
            </div>
        </div>
    );
}

export default EmployeeStep3;
