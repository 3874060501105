// Filename - pages/about.js

import React from 'react';
import './Styles/About.css';
import { useNavigate } from 'react-router-dom';

const About = () => {
    const navigate = useNavigate();
    return (
        <div className="about-body">
            <header className="about-header">
                <h1>Our Story</h1>
            </header>
            <section className="mission">
                <h2 className="about-section-header">Goal setting is hard</h2>
                <p className="about-section-text">Employees worldwide participate in tedious corporate goal setting activities on a regular basis.
                    Effective goal setting guidelines are not set, and the process can take weeks. 
                    By the time goals are approved, most are never looked at or thought of again.
                    Employees are left to wonder how they are doing when performance reviews are due, leading to a lack of motivation and engagement.
                    <br/>
                    <br/>
                    Managers also suffer from a lack of guidance when it comes to goal setting, and are left wanting a better solution to get more out of their teams.
                    <br/>
                    <br/>
                    <strong>We know because we've been there.</strong>
                    <br/>
                    <br/>
                    Goal setting does not have to be hard. We are here to help.
                </p>
            </section>
            <section className="story">
                <h2 className="about-section-header">Inspire your workforce</h2>
                <p className="about-section-text">Astellyst was born out of a desire to change the way businesses approach goal setting and performance management. 
                    By aligning the process with personal objectives and company aspirations, we bring goals to life.
                    <br/>
                    <br/>
                    With our AI-enabled platform, we:
                </p>
                <ul className="about-section-list">
                    <li className="about-section-list-item">Suggest goals tailored by your company's mission and employee profiles</li>
                    <li className="about-section-list-item">Track employee achievements with AI-powered analytics</li>
                    <li className="about-section-list-item">Provide feedback and recognition when goals are achieved</li>
                </ul>
            </section>
            <section className="call-to-action">
                <h2 className="about-section-header">There are talents hidden in all of us</h2>
                <p className="about-section-text">Sign up now to unlock your team's potential</p>
                <button className="about-sign-up-button" onClick={() => { navigate('/SalesQuotePage'); window.scrollTo(0, 0); }}>Sign up</button>
            </section>
        </div>
    );
};

export default About;
