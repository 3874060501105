import React, { useState, useRef, useEffect } from 'react';
import ManagerStep2 from './ManagerStep2';
import ManagerGoalReview from './ManagerGoalReview';
import ManagerStep3 from './ManagerStep3';
import '../Styles/ManagerMaster.css';
import { attachAutoExpand, detachAutoExpand } from '../../../Util/autoExpand';
import { useLocation } from 'react-router-dom';

function ManagerMaster({ companyMission, goals = [] }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialStep = parseInt(queryParams.get('step'), 10) || 0;

    const [currentStep, setCurrentStep] = useState(initialStep);
    const [teamGoals, setTeamGoals] = useState(() => JSON.parse(localStorage.getItem('teamGoals')) || [{ text: '', confirmed: false }]);
    const textareaRef = useRef(null);

    const nextStep = () => setCurrentStep(prev => prev + 1);
    const prevStep = () => setCurrentStep(prev => prev - 1);
    const skipStep = () => setCurrentStep(prev => prev + 1);

    const saveForLater = () => {
        const dataToSave = {
            userId: "",
            data: {
                teamGoals,
            },
        };

        fetch('/api/team-goals/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSave),
        })
        .then(response => response.json())
        .then(data => {
            alert('Progress saved successfully!');
        })
        .catch(error => {
            console.error('Error saving progress:', error);
            alert('Failed to save progress. Please try again.');
        });
    };

    const submitData = () => {
        const dataToSubmit = {
            userId: "",
            data: {
                teamGoals,
            },
        };

        fetch('/api/team-goals/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSubmit),
        })
        .then(response => response.json())
        .then(data => {
            alert('Data submitted successfully!');
        })
        .catch(error => {
            console.error('Error submitting data:', error);
            alert('Failed to submit data. Please try again.');
        });
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        attachAutoExpand(textarea);

        return () => {
            detachAutoExpand(textarea);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem('teamGoals', JSON.stringify(teamGoals));
    }, [teamGoals]);

    // Clear localStorage on component unmount (optional)
    useEffect(() => {
        return () => {
            localStorage.removeItem('teamGoals');
        };
    }, []);

    return (
        <div className='manager-goal-set-body'>
            {currentStep === 0 && (
                <ManagerGoalReview
                    companyMission={companyMission}
                    goals={goals}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    skipStep={skipStep}
                    textareaRef={textareaRef}
                />
            )}
            {currentStep === 1 && (
                <ManagerStep2
                    teamGoals={teamGoals}
                    setTeamGoals={setTeamGoals}
                    textareaRef={textareaRef}
                    prevStep={prevStep}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    skipStep={skipStep}
                />
            )}
            {currentStep === 2 && (
                <ManagerStep3
                    teamGoals={teamGoals}
                    setTeamGoals={setTeamGoals}
                    prevStep={prevStep}
                    submitData={submitData}
                    saveForLater={saveForLater}
                />
            )}
        </div>
    );
}

export default ManagerMaster;
