import React from 'react';
import './Styles/home.css';
import SignUpForm from '../../Components/MailChimp/NewsletterSignUp';
import { useNavigate } from 'react-router-dom';



const NavBar = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <div className="landingPageNavBar">
            <div className="landingPageNavBarLeft">            
                <span className="navLink-logo" onClick={() => handleNavigation('/')}>Astellyst</span>
                <span className="navLink" onClick={() => handleNavigation('/About')}>About</span>
                <span className="navLink" onClick={() => handleNavigation('/Contact')}>Contact</span>
                <span className="navLink" onClick={() => handleNavigation('/Resources')}>Resources</span>
            </div>
            <div className="landingPageNavBarCenter">
            </div>
            <div className="landingPageNavBarRight">
                <span className="navLink" onClick={() => handleNavigation('/LogInPage')}>Sign In</span>
            </div>
        </div>
    );
};

const Home = () => {
    return (
        <div className='home-body'>
            <NavBar />
            <div className="landingPageHero">
                <h1 className="landingPageHeroTitle">Dream Big Together</h1>
                <h3 className="landingPageHeroSubtitle">Performance Management that Inspires</h3>
                <p>Get on the early access list now</p>
                <SignUpForm className="signUpForm" /> {/* Ensure the className is passed */}
            </div>
        </div>
    );
};

export default Home;