import React from 'react';
import './Styles/Resources.css'; // Assuming you have a CSS file for styling

const resourcesData = [
    {
        title: "Employee Engagement: The Key to Improving Performance",
        description: "This research paper explores employee engagement and the positive relationship it provides to job satisfaction, employee commitment and organization citizenship behavior.",
        link: "https://www.researchgate.net/publication/49586552_Employee_Engagement_The_Key_to_Improving_Performance", // Replace with actual link
        buttonText: "Read Here"
    },
    {
        title: "How effective goal-setting motivates employees",
        description: "McKinsey outlines three key ways that effective goal-setting can motivate employees in this short blog post.",
        link: "https://www.mckinsey.com/capabilities/people-and-organizational-performance/our-insights/the-organization-blog/how-effective-goal-setting-motivates-employees", // Replace with actual link
        buttonText: "Read Here"
    },
    {
        title: "Making Sure Your Employees Succeed",
        description: "HBR provides key principles to remember when setting goals for your employees.",
        link: "https://hbr.org/2011/02/making-sure-your-employees-suc",
        buttonText: "Read Here"
    }
];

const Resources = () => {
    return (
        <div className="resources-container">
            <h1>Performance Management is important</h1>
            <h3>Don't just take our word for it, explore expert insights below.</h3>
            <section className="resources-section">
                <div className="resources-grid">
                    {resourcesData.map((resource, index) => (
                    <div className="resource-card" key={index}>
                        <h3>{resource.title}</h3>
                        <p>{resource.description}</p>
                        <a href={resource.link} className="resource-button" target="_blank" rel="noopener noreferrer">{resource.buttonText}</a>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Resources;
