import React, { useState, useEffect } from 'react';

function Step3({ companyMission, goals, prevStep, submitData, saveForLater, setCompanyMission }) {
    const [isEditingMission, setIsEditingMission] = useState(false);
    const [editedMission, setEditedMission] = useState(companyMission);
    const [isEditingGoals, setIsEditingGoals] = useState(false);
    const [editedGoals, setEditedGoals] = useState([]);

    useEffect(() => {
        // Initialize editedGoals with at least one empty goal if there are no confirmed goals
        if (goals.length === 0 || goals.every(goal => !goal.text.trim())) {
            setEditedGoals(['']);
        } else {
            setEditedGoals(goals.map(goal => goal.text));
        }
    }, [goals]);

    const toggleEditMission = () => {
        if (isEditingMission) {
            // Update the company mission when done editing
            setCompanyMission(editedMission);
        }
        setIsEditingMission(!isEditingMission);
    };

    const toggleEditGoals = () => {
        if (isEditingGoals) {
            const nonEmptyGoals = editedGoals.filter(goalText => goalText.trim() !== '');
            setEditedGoals(nonEmptyGoals.length > 0 ? nonEmptyGoals : ['']);
            goals.length = 0;
            nonEmptyGoals.forEach(goalText => {
                goals.push({ text: goalText, confirmed: goalText !== '' });
            });
        }
        setIsEditingGoals(!isEditingGoals);
    };

    const addGoal = () => {
        if (editedGoals.length < 5) {
            setEditedGoals([...editedGoals, '']); // Add a new empty goal
        }
    };

    const deleteGoal = (index) => {
        if (editedGoals.length > 1) { // Only allow deletion if more than one goal exists
            const newGoals = [...editedGoals];
            newGoals.splice(index, 1); // Remove the goal at the specified index
            setEditedGoals(newGoals);
        }
    };

    return (
        <div className='admin-goal-set-container'>
            <h2>Review Your Information</h2>
            <div className='admin-goal-set-review-container'>
                <h3>Company Mission</h3>
                <div className='admin-goal-set-flex-container'>
                    {isEditingMission ? (
                        <textarea
                            value={editedMission} 
                            onChange={(e) => setEditedMission(e.target.value)} 
                            className='admin-goal-set-input' 
                        />
                    ) : (
                        <p className='admin-goal-set-review-text'>{editedMission || "No company mission provided."}</p>
                    )}
                    <button onClick={toggleEditMission} className='admin-goal-set-edit-button'>
                        {isEditingMission ? 'Done Editing' : 'Edit'}
                    </button>
                </div>
                <h3>Company Goals</h3>
                <div className='admin-goal-set-flex-container'>
                    {isEditingGoals ? (
                        <ul>
                            {editedGoals.map((goal, index) => (
                                <li className='admin-goal-set-review-list-edit' key={index}>
                                    <textarea 
                                        className='admin-goal-set-input'
                                        value={goal} 
                                        onChange={(e) => {
                                            const newGoals = [...editedGoals];
                                            newGoals[index] = e.target.value;
                                            setEditedGoals(newGoals);
                                        }} 
                                    />
                                    {editedGoals.length > 1 && (
                                        <button 
                                            onClick={() => deleteGoal(index)} 
                                            className='admin-goal-delete-button'
                                        >
                                            Delete
                                        </button>
                                    )}
                                </li>
                            ))}
                            <div className='admin-goal-set-button-review-wrapper'>
                                {editedGoals.length < 5 && (
                                    <button onClick={addGoal} className='admin-goal-set-add-button'>Add Goal</button>
                                )}
                                <button onClick={toggleEditGoals} className='admin-goal-set-button'>Done Editing</button>
                            </div>
                        </ul>
                    ) : (
                        <ul className='admin-goal-set-review-list-text'>
                            {goals.length > 0 && goals.filter(goal => goal.confirmed && goal.text).length > 0 ? (
                                goals.filter(goal => goal.confirmed && goal.text).map((goal, index) => (
                                    <li className='admin-goal-set-list-item' key={index}>{goal.text}</li>
                                ))
                            ) : (
                                <p className='admin-company-goal-set-review-text'>No company goals provided.</p>
                            )}
                        </ul>
                    )}
                    {!isEditingGoals && (
                        <button onClick={toggleEditGoals} className='admin-goal-set-edit-button'>Edit</button>
                    )}
                </div>
            </div>
            <div className='admin-goal-set-button-container'>
                <button onClick={prevStep} className='admin-goal-set-button'>Back</button>
                <button onClick={saveForLater} className='admin-goal-set-button'>Save for Later</button>
                <button onClick={submitData} className='admin-goal-set-submit-button'>Submit</button>
            </div>
        </div>
    );
}

export default Step3;