import React, { useState, useEffect, useRef } from "react";
import '../Styles/ProgressPage.css';
import { attachAutoExpand, detachAutoExpand } from '../../../Util/autoExpand';
import { useNavigate } from "react-router-dom"; // Import useNavigate

function ProgressPage() {
    const [goals, setGoals] = useState([]);
    const [commentsVisible, setCommentsVisible] = useState([]); // Track visibility of comments
    const [buttonText, setButtonText] = useState([]); // Track button text
    const [comments, setComments] = useState([]); // Track comments for each goal
    const [currentComment, setCurrentComment] = useState([]); // Track current input for comments

    const textareaRefs = useRef([]);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        // Placeholder data for goals
        const placeholderGoals = [
            { name: "Complete Project A", progress: 0 },
            { name: "Attend Training Session", progress: 50 },
            { name: "Submit Report", progress: 100 },
            { name: "Improve Team Collaboration", progress: 25 },
            { name: "Learn New Technology", progress: 10 },
        ];
        setGoals(placeholderGoals);
        setComments(Array(placeholderGoals.length).fill("")); // Initialize comments
        setCommentsVisible(Array(placeholderGoals.length).fill(false)); // Initialize visibility
        setButtonText(Array(placeholderGoals.length).fill("Add Comment")); // Initialize button text
        setCurrentComment(Array(placeholderGoals.length).fill("")); // Initialize current comment input
    }, []);

    const handleProgressChange = (index, newProgress) => {
        const updatedGoals = [...goals];
        updatedGoals[index].progress = newProgress; // Update the progress for the specific goal
        setGoals(updatedGoals);
    };

    const handleCommentToggle = (index) => {
        const updatedVisibility = [...commentsVisible];
        updatedVisibility[index] = !updatedVisibility[index]; // Toggle visibility for the specific goal
        
        const updatedButtonText = [...buttonText];
        updatedButtonText[index] = updatedVisibility[index] ? "Confirm" : "Add Comment"; // Change button text based on visibility

        setCommentsVisible(updatedVisibility);
        setButtonText(updatedButtonText);
    };

    const handleCommentSubmit = (index) => {
        const updatedComments = [...comments];
        updatedComments[index] = currentComment[index]; // Save the comment for the specific goal
        setComments(updatedComments);
        setCurrentComment((prev) => {
            const newComments = [...prev];
            newComments[index] = ""; // Clear the current comment input after submission
            return newComments;
        });
        handleCommentToggle(index); // Close the comment area after submission
    };

    useEffect(() => {
        // Attach autoExpand to each textarea when they are rendered
        const currentRefs = textareaRefs.current; // Store the current refs in a variable
        commentsVisible.forEach((visible, index) => {
            if (visible && currentRefs[index]) {
                attachAutoExpand(currentRefs[index]);
            }
        });

        // Cleanup function to detach autoExpand
        return () => {
            commentsVisible.forEach((visible, index) => {
                if (visible && currentRefs[index]) {
                    detachAutoExpand(currentRefs[index]);
                }
            });
        };
    }, [commentsVisible]); // Run effect when commentsVisible changes

    const handleSubmit = () => {
        // Logic to save the state of changes (e.g., send to an API or log to console)
        console.log("Goals:", goals);
        console.log("Comments:", comments);
    };

    const handleGoBack = () => {
        navigate(-1); // Navigate to the previous page
    };

    return (
        <div className="progress-page-body">
            <header className="progress-page-header">
                <h2 className="progress-page-header-text">Goal Progress</h2>
            </header>
            <div className="progress-page-goals">
                {goals.map((goal, index) => (
                    <div key={index}>
                        <h5 className="progress-page-goal-name">{goal.name}</h5>
                        <div className="progress-page-goal-progress-container">
                            <div className="progress-page-goal-progress-percentage-container">
                                <input
                                    className="progress-page-goal-progress"
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={goal.progress}
                                onChange={(e) => handleProgressChange(index, e.target.value)}
                            />
                                <span className="progress-page-goal-progress-percentage">{goal.progress}%</span>
                            </div>
                            {!commentsVisible[index] && ( // Only show the Add Comment button when the comment area is not visible
                                <button 
                                className={`progress-page-goal-comment-button ${commentsVisible[index] ? '' : 'hidden'}`} 
                                onClick={() => handleCommentToggle(index)}
                            >
                                {buttonText[index]}
                            </button>
                        )}
                        </div>
                        {commentsVisible[index] && ( // Conditional rendering of the text area and Confirm button

                            <div className="progress-page-goal-comment-container">
                                <textarea
                                    ref={(el) => (textareaRefs.current[index] = el)}
                                    className="progress-page-goal-comment"
                                    placeholder="Add your comment here..."
                                    value={currentComment[index]} // Bind the current comment input
                                    onChange={(e) => {
                                        const updatedCurrentComment = [...currentComment];
                                        updatedCurrentComment[index] = e.target.value; // Update current comment in state
                                        setCurrentComment(updatedCurrentComment);
                                    }}
                                />
                                <button className="progress-page-goal-confirm-button" onClick={() => handleCommentSubmit(index)}>Confirm</button>
                            </div>
                        )}
                        {comments[index] && ( // Display the comment below the progress bar
                            <p className="progress-page-goal-comment-display">{comments[index]}</p>
                        )}
                    </div>
                ))}
            </div>
            <div className="progress-page-submit-container">
            <button className="progress-page-goal-comment-button" onClick={handleGoBack}>
                    Go Back
                </button>
                <button className="progress-page-goal-comment-button" onClick={handleSubmit}>
                    Submit Changes
                </button>
            </div>
        </div>
    );
}

export default ProgressPage;
