// src/components/Footer.js

import React from 'react';
import './Footer.css'; // Import the CSS file for the footer

const Footer = () => {
    return (
        <footer>
            <p>
            <a className="footer-link" href="/Support">Support</a> | 
            <a className="footer-link" href="/Careers"> Careers</a> | 
            <a className="footer-link" href="/TermsOfUse"> Terms of Use</a> | 
            <a className="footer-link" href="/PrivacyPolicy"> Privacy</a> | 
            <a className="footer-link" href="/Contact"> Contact Us</a>
            </p>
        </footer>
    );
};

export default Footer;