import React from 'react';
import '../Styles/Billing.css'; // Assuming you have a CSS file for styles

const Billing = () => {
    
    const handleBackLinkClick = () => {
        window.history.back();
    };
    
    return (
        <div className='billing-body'>
            <p onClick={handleBackLinkClick} className="back-link">
                Back
            </p>
            <div className="billing-container">    
            <div className="account-info">
                <h2>Basic Information</h2>
                <p><strong>Name: </strong>Name</p>
                <p><strong>Email: </strong>Email</p>
                <div className="account-settings">
                    <h3>Account</h3>
                    <div className="account-buttons">
                        <button className='billing-button'>Manage Subscription</button>
                    </div>
                </div>
            </div>
            <div className="usage-info">
                <h2>Usage</h2>
                <div className="usage-stats">
                    <p>Premium models: <strong>37 / 500</strong></p>
                    <div className="progress-bar premium">
                        <div className="progress premium-progress"></div>
                    </div>
                    <p>You've used 37 requests out of your 500 monthly fast requests quota.</p>
                </div>
                <div className="usage-stats">
                    <p>gpt-4o-mini or cursor-small: <strong>185 / No Limit</strong></p>
                    <div className="progress-bar gpt">
                        <div className="progress gpt-progress"></div>
                    </div>
                    <p>You've used 185 fast requests of this model. You have no monthly quota.</p>
                </div>
                </div>
            </div>
        </div>
    );
};

export default Billing;
