import React from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
	const handleScrollToTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<div className="navbar-container">
			<div className="nav">
				<NavLink to="/" className="nav-link-logo" onClick={handleScrollToTop}>Astellyst</NavLink>
				<NavLink to="/About" className="nav-link" onClick={handleScrollToTop}>About</NavLink>
				<NavLink to="/Contact" className="nav-link" onClick={handleScrollToTop}>Contact</NavLink>
				<NavLink to="/Resources" className="nav-link" onClick={handleScrollToTop}>Resources</NavLink>
			</div>
		</div>
	);
};

export default Navbar;
