import React, { useState, useEffect } from 'react';
import '../Styles/EmployeeDirectory.css'; // Ensure this CSS file contains the necessary styles

const EmployeeDirectory = () => {
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        // Move placeholderData inside useEffect
        const placeholderData = [
            { id: 1, name: 'Michael Pittman', position: 'Chief Operating Officer', reportsTo: null },
            { id: 2, name: 'Christine Dolan', position: 'Chief Human Resources Officer', reportsTo: 1 },
            { id: 3, name: 'Charles Braun', position: 'VP Global People Operations', reportsTo: 1 },
            { id: 4, name: 'Aanya Singh', position: 'Administrative Support', reportsTo: 2 },
            { id: 5, name: 'Carla Grante', position: 'Sales Manager-EAST', reportsTo: 3 },
            { id: 6, name: 'Tessa Walker', position: 'HR Business Partner Global', reportsTo: 2 },
            { id: 7, name: 'Benoit Leistner', position: 'HR Business Office Director', reportsTo: 3 },
            // ... more employees
        ];

        setEmployees(placeholderData);
    }, []); // Keep the dependency array empty

    const renderEmployee = (employee) => {
        const subordinates = employees.filter(emp => emp.reportsTo === employee.id);
        return (
            <div key={employee.id} className="employee">
                <div className="employee-box">
                    <h2 className="employee-name">{employee.name}</h2>
                    <p className="employee-position">{employee.position}</p>
                </div>
                {subordinates.length > 0 && (
                    <div className="subordinates">
                        {subordinates.map(subordinate => (
                            <div key={subordinate.id} className="subordinate">
                                <div className="line" />
                                {renderEmployee(subordinate)}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    const ceo = employees.find(emp => emp.reportsTo === null);

    const handleBackLinkClick = () => {
        window.history.back();
    };

    return (
        <div className='employee-directory-body'>
            <p onClick={handleBackLinkClick} className="back-link">
                Back
            </p>
            <div className="employee-directory">
                <h1>Employee Directory</h1>
                <div className="org-chart">
                    {ceo && renderEmployee(ceo)}
                </div>
            </div>
        </div>
    );
};


export default EmployeeDirectory;
