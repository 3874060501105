import axios from 'axios';

export const subscribeToNewsletter = async (email) => {
    const url = 'https://astellyst.com/api/mailchimp/subscribe';
    const data = { email };

    try {
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error('Error subscribing to Mailchimp:', error);
        throw error;
    }
};
