// src/utils/autoExpand.js

export const autoExpand = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
};

export const attachAutoExpand = (textarea) => {
    if (textarea) {
        textarea.addEventListener('input', () => autoExpand(textarea));
        autoExpand(textarea);
    }
};

export const detachAutoExpand = (textarea) => {
    if (textarea) {
        textarea.removeEventListener('input', () => autoExpand(textarea));
    }
};