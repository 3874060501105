import React from 'react';
import './Styles/Support.css';

const SupportPage = () => {
    return (
        <div className="support-body">
            <h1 className="support-title">Support</h1>
            <div className="support-container">
            <div className="support-section">
                <h2 className="support-subtitle">Account and Billing Questions</h2>
                <p className="support-text">
                    For any account or billing related inquiries, please email us at 
                    <a href="mailto:billing@astellyst.com" className="support-link"> billing@astellyst.com</a>
                </p>
            </div>
            
            <div className="support-section">
                <h2 className="support-subtitle">Other Support Questions</h2>
                <p className="support-text">
                    For all other support questions, please contact us at 
                    <a href="mailto:support@astellyst.com" className="support-link"> support@astellyst.com</a>
                </p>
            </div>
            </div>
        </div>
    );
};

export default SupportPage;
