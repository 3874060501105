import React from 'react';
import { useNavigate } from 'react-router-dom';
// Import necessary components and styles
// ...

function EmployeeStep1({ companyMission, goals, teamGoals, nextStep, userRole }) {
    const navigate = useNavigate();
    
    const handleBackClick = () => {
        if (userRole === 'manager') {
            navigate('/managerportal'); // Navigate to manager portal
        } else {
            navigate('/employeeportal'); // Navigate to employee portal
        }
    };

    return (
        <div className='employee-goal-set-container'>
            <h2 className='employee-goal-review-mission-title'>Company Mission</h2>
            <p className='employee-goal-review-text'>Remember to think about the company mission when setting your goals.</p>
            <p className='employee-goal-review-mission'>{companyMission}</p>
            
            <h2 className='employee-goal-review-goals-title'>Company Goals</h2>
            <p className='employee-goal-review-text'>These are goals your company is working towards. How can you help your company achieve these goals?</p>
            <ul className='employee-goal-review-goals-list'>
                {goals.map((goal, index) => (
                    <li key={index}>{goal}</li>
                    ))}
            </ul>

            <h2 className='employee-goal-review-goals-title'>Your Manager's Team Goals</h2>
            <p className='employee-goal-review-text'>These are goals your manager has set for your team. How can you help your team achieve these goals?</p>
            <ul className='employee-goal-review-goals-list'>
                {teamGoals.map((goal, index) => (
                    <li key={index}>{goal.text}</li>
                    ))}
            </ul>
            <div className='employee-goal-set-button-container'>
                <button className='employee-goal-set-button' onClick={handleBackClick}>Back</button>
                <button className='employee-goal-set-button' onClick={nextStep}>Next</button>
            </div>
        </div>
    );
}

export default EmployeeStep1;
