import React, { useState } from 'react';
import '../Styles/AdminPortal.css';
import { Link } from 'react-router-dom';

const AdminPortal = () => {
    const [activeTab, setActiveTab] = useState('goal-setting');

    const renderContent = () => {
        switch (activeTab) {
            case 'goal-setting':
                return (
                    <div>
                        <h2>Set Company Goals</h2>
                        <p>Set universal goals for all employees and managers to see.</p>
                        <div className='goal-setting-button-container'>
                            <Link to="/AdminMaster">
                                <button className='goal-setting-button'>Set Goals</button>
                            </Link>
                            <Link to="/AdminMaster?step=2">
                                <button className='goal-setting-button'>Review Goals</button>
                            </Link>
                        </div>
                    </div>
                );
            case 'employee-directory':
                return (
                    <div>
                        <h2>Employee Directory</h2>
                        <p>View and manage the employee directory.</p>
                        <Link to="/EmployeeDirectory">
                            <button className='goal-setting-button'>View Directory</button>
                        </Link>
                    </div>
                );
            case 'billing-usage':
                return (
                    <div>
                        <h2>Billing & Usage</h2>
                        <p>View billing and usage statistics.</p>
                        <Link to="/Billing">
                            <button className='goal-setting-button'>View Billing</button>
                        </Link>
                    </div>
                );
                case 'set-goals':
                return (
                    <div>
                        <h2>Set Team Goals</h2>
                        <p>Define goals for your team to achieve.</p>
                        <div className='goal-setting-button-container'>
                            <Link to="/ManagerMaster">
                                <button className='goal-setting-button'>Set Team Goals</button>
                            </Link>
                            <Link to="/ManagerMaster?step=2">
                                <button className='goal-setting-button'>Review Goals</button>
                            </Link>
                        </div>
                    </div>
                );
            case 'your-team':
                return (
                    <div>
                        <h2>Your Team</h2>
                        <p>View and manage your team members.</p>
                        <Link to="/TeamMembers">
                            <button className='goal-setting-button'>View Team</button>
                        </Link>
                    </div>
                );
            case 'set-your-goals':
                return (
                    <div>
                        <h2>Set Your Goals</h2>
                        <p>Establish your personal goals.</p>
                        <Link to="/EmployeeMaster">
                            <button className='goal-setting-button'>Set Your Goals</button>
                        </Link>
                    </div>
                );
            case 'your-progress':
                return (
                    <div>
                        <h2>Your Progress</h2>
                        <p>View your progress.</p>
                        <Link to="/ProgressPage">
                            <button className='goal-setting-button'>View Progress</button>
                        </Link>
                    </div>
                );
            default:
                return <div>Please select a tab.</div>;
        }
    };

    return (
        <div className="admin-portal">
            <div className="admin-portal-sidebar">
                <button className="admin-portal-sidebar-button" onClick={() => setActiveTab('goal-setting')}>Set Company Goals</button>
                <button className="admin-portal-sidebar-button" onClick={() => setActiveTab('employee-directory')}>Employee Directory</button>
                <button className="admin-portal-sidebar-button" onClick={() => setActiveTab('billing-usage')}>Billing & Usage</button>
                <button className="admin-portal-sidebar-button" onClick={() => setActiveTab('set-goals')}>Set Team Goals</button>
                <button className="admin-portal-sidebar-button" onClick={() => setActiveTab('your-team')}>Your Team</button>
                <button className="admin-portal-sidebar-button" onClick={() => setActiveTab('set-your-goals')}>Set Your Goals</button>
                <button className="admin-portal-sidebar-button" onClick={() => setActiveTab('your-progress')}>Your Progress</button>
            </div>
            <div className="admin-portal-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default AdminPortal;