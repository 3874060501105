import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

// Pages
import Home from "./Pages/HomePage/home";
import About from "./Pages/About/about";
import Contact from "./Pages/Contact/Contact";
import Resources from "./Pages/Resources/Resources";
import SupportPage from "./Pages/Support/Support";
import CareersPage from "./Pages/Careers/Careers";
import TermsOfUse from "./Pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import SalesQuotePage from "./Pages/SalesQuote/SalesQuotePage";
import LogInPage from "./Pages/LogInSelection/LogInPage";
import ForgotPassword from "./Pages/LogInSelection/ForgotPassword";
import LogInSelection from "./Pages/LogInSelection/LogInSelection";
import AdminPortal from "./Pages/AdminPortal/AdminPortalPage/AdminPortal";
import AdminMaster from "./Pages/AdminPortal/AdminGoalSet/AdminMaster";
import EmployeeDirectory from "./Pages/AdminPortal/AdminPortalPage/EmployeeDirectory";
import Billing from "./Pages/AdminPortal/AdminPortalPage/Billing";
import ManagerPortal from "./Pages/ManagerPortal/ManagerPortalPage/ManagerPortal";
import ManagerMaster from "./Pages/ManagerPortal/ManagerGoalSet/ManagerMaster";
import TeamMembers from "./Pages/ManagerPortal/ManagerPortalPage/TeamMembers";
import EmployeePortal from "./Pages/EmployeePortal/EmployeePortalPage/EmployeePortal";
import EmployeeMaster from "./Pages/EmployeePortal/EmployeeGoalSet/EmployeeMaster";
import ProgressPage from './Pages/EmployeePortal/EmployeeProgress/ProgressPage';

// Components
import WithNav from "./Components/NavFunctionality/WithNav";
import WithoutNav from "./Components/NavFunctionality/WithoutNav";
import Footer from "./Components/Footer/Footer";

function Layout({ children }) {
  return (
    <>
      {children}
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout>
        <div className="content">
          <Routes>
            <Route element={<WithoutNav />}>
              <Route exact path="/" element={<Home />} />
            </Route>
            <Route element={<WithNav />}>
              <Route exact path="/about" element={<About />} />
              <Route exact path="/Contact" element={<Contact />} /> 
              <Route exact path="/Resources" element={<Resources />} />
              <Route exact path="/Support" element={<SupportPage />} />
              <Route exact path="/Careers" element={<CareersPage />} />
              <Route exact path="/TermsOfUse" element={<TermsOfUse />} />
              <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route exact path="/SalesQuotePage" element={<SalesQuotePage />} />
              <Route exact path="/LogInPage" element={<LogInPage />} />
              <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
              <Route exact path="/LogInSelection" element={<LogInSelection />} />
              <Route exact path="/AdminPortal" element={<AdminPortal />} />
              <Route exact path="/AdminMaster" element={<AdminMaster />} />
              <Route exact path="/EmployeeDirectory" element={<EmployeeDirectory />} />
              <Route exact path="/Billing" element={<Billing />} />
              <Route exact path="/ManagerPortal" element={<ManagerPortal />} />
              <Route exact path="/ManagerMaster" element={<ManagerMaster />} />
              <Route exact path="/TeamMembers" element={<TeamMembers />} />
              <Route exact path="/EmployeePortal" element={<EmployeePortal />} />
              <Route exact path="/EmployeeMaster" element={<EmployeeMaster />} />
              <Route exact path="/ProgressPage" element={<ProgressPage />} />
            </Route>
          </Routes>
        </div>
      </Layout>
    </Router>
  );
}

export default App;
