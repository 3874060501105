import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AdminStep1({ tempMission, setTempMission, setCompanyMission, setIsConfirmed, nextStep, skipStep, saveForLater, isConfirmed, textareaRef }) {
    const navigate = useNavigate();

    // Update tempMission whenever companyMission changes
    useEffect(() => {
        setTempMission(tempMission);
    }, [tempMission, setTempMission]);

    return (
        <div className='admin-goal-set-container'>
            <h2 className='admin-goal-set-title'>Company Mission</h2>
            <p className='admin-goal-set-description'>Please enter your company mission statement below. It will help us tailor goals to your company.</p>
            {isConfirmed ? (
                <div className='admin-goal-set-flex-container'>
                    <p className='admin-goal-set-confirmed'>{tempMission}</p>
                    <button className='admin-goal-set-edit-button' onClick={() => setIsConfirmed(false)}>Edit</button>
                </div>
            ) : (
                <div className='admin-goal-set-flex-container'>
                    <textarea 
                        ref={textareaRef}
                        className='admin-goal-set-input'
                        placeholder='Enter your company mission' 
                        value={tempMission}
                        onChange={(e) => {
                            setTempMission(e.target.value);
                            setCompanyMission(e.target.value);
                        }}
                    />
                    <button className='admin-goal-set-confirm-button' onClick={() => {
                        setIsConfirmed(true);
                        setCompanyMission(tempMission);
                    }}>Confirm</button>
                </div>
            )}
            <div className='admin-goal-set-button-container'>
                <button className='admin-goal-set-button' onClick={() => navigate('/AdminPortal')}>Back</button>
                <button className='admin-goal-set-button' onClick={saveForLater}>Save for Later</button>
                <button className='admin-goal-set-button' onClick={skipStep}>Skip</button>
                <button className='admin-goal-set-button' onClick={nextStep}>Next</button>
            </div>
        </div>
    );
}

export default AdminStep1;