import React, { useState, useRef, useEffect } from 'react';
import Step1 from './AdminStep1';
import Step2 from './AdminStep2';
import Step3 from './AdminStep3';
import { attachAutoExpand, detachAutoExpand } from '../../../Util/autoExpand';
import '../Styles/AdminMaster.css';
import { useLocation } from 'react-router-dom';

function AdminMaster() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialStep = parseInt(queryParams.get('step'), 10) || 0;

    const [step, setStep] = useState(initialStep);
    const [companyMission, setCompanyMission] = useState(() => localStorage.getItem('companyMission') || '');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [goals, setGoals] = useState(() => JSON.parse(localStorage.getItem('goals')) || [{ text: '', confirmed: false }]);
    const textareaRef = useRef(null);

    const nextStep = () => setStep(prevStep => prevStep + 1);
    const prevStep = () => setStep(prevStep => prevStep - 1);
    const skipStep = () => setStep(prevStep => prevStep + 1);

    const saveForLater = () => {
        const dataToSave = {
            userId: "",
            data: {
                companyMission,
                goals,
            },
        };

        fetch('/api/company-mission/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSave),
        })
        .then(response => response.json())
        .then(data => {
            alert('Progress saved successfully!');
        })
        .catch(error => {
            console.error('Error saving progress:', error);
            alert('Failed to save progress. Please try again.');
        });
    };

    const submitData = () => {
        const dataToSubmit = {
            userId: "",
            data: {
                companyMission,
                goals,
            },
        };

        fetch('/api/company-mission/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSubmit),
        })
        .then(response => response.json())
        .then(data => {
            alert('Data submitted successfully!');
        })
        .catch(error => {
            console.error('Error submitting data:', error);
            alert('Failed to submit data. Please try again.');
        });
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        attachAutoExpand(textarea);

        return () => {
            detachAutoExpand(textarea);
        };
    }, []);

    // Save data to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('companyMission', companyMission);
    }, [companyMission]);

    useEffect(() => {
        localStorage.setItem('goals', JSON.stringify(goals));
    }, [goals]);

    // Clear localStorage on component unmount (optional)
    useEffect(() => {
        return () => {
            localStorage.removeItem('companyMission');
            localStorage.removeItem('goals');
        };
    }, []);

    return (
        <div className='admin-goal-set-body'>
            {step === 0 && (
                <Step1
                    tempMission={companyMission}
                    setTempMission={setCompanyMission}
                    setCompanyMission={setCompanyMission}
                    setIsConfirmed={setIsConfirmed}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    skipStep={skipStep}
                    isConfirmed={isConfirmed}
                    textareaRef={textareaRef}
                />
            )}
            {step === 1 && (
                <Step2
                    goals={goals}
                    setGoals={setGoals}
                    prevStep={prevStep}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    skipStep={skipStep}
                    textareaRef={textareaRef}
                />
            )}
            {step === 2 && (
                <Step3
                    companyMission={companyMission}
                    goals={goals}
                    prevStep={prevStep}
                    submitData={submitData}
                    saveForLater={saveForLater}
                    setCompanyMission={setCompanyMission}
                />
            )}
        </div>
    );
}

export default AdminMaster;