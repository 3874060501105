import React from 'react';

function Step2({ goals, setGoals, prevStep, nextStep, saveForLater, skipStep, textareaRef }) {
    const addGoal = () => {
        if (goals.length < 5) {
            setGoals([...goals, { text: '', confirmed: false }]);
        }
    };

    const handleGoalChange = (index, value) => {
        const newGoals = [...goals];
        newGoals[index].text = value;
        setGoals(newGoals);
    };

    const toggleGoalConfirmation = (index) => {
        const newGoals = [...goals];
        newGoals[index].confirmed = !newGoals[index].confirmed;
        setGoals(newGoals);
    };

    const deleteGoal = (index) => {
        const newGoals = goals.filter((_, i) => i !== index);
        setGoals(newGoals);
    };

    return (
        <div className='admin-goal-set-container'>
            <h2 className='admin-goal-set-title'>Set Your Company Goals</h2>
            <p className='admin-goal-set-description'>Please enter up to five company-wide goals below. These will be considered when creating personalized goals for your employees.</p>
            {goals.map((goal, index) => (
                <div key={index} className='admin-goal-set-flex-container'>
                    {goal.confirmed ? (
                        <p className='admin-goal-set-confirmed'>{goal.text}</p>
                    ) : (
                        <textarea
                            className='admin-goal-set-input'
                            placeholder='Enter a company goal here'
                            value={goal.text}
                            onChange={(e) => handleGoalChange(index, e.target.value)}
                            ref={textareaRef}
                        />
                    )}
                    <div className="admin-goal-set-button-wrapper">
                        <button onClick={() => toggleGoalConfirmation(index)} className={`admin-goal-set-confirm-button ${goal.confirmed ? 'admin-goal-set-edit-button' : ''}`}>
                            {goal.confirmed ? 'Edit' : 'Confirm'}
                        </button>
                        <button onClick={() => deleteGoal(index)} className='admin-goal-delete-button'>Delete</button>
                    </div>
                </div>
            ))}
            {goals.length < 5 && (
                <button onClick={addGoal} className='admin-goal-set-add-button'>Add Goal</button>
            )}
            <div className='admin-goal-set-button-container'>
                <button onClick={prevStep} className='admin-goal-set-button'>Back</button>
                <button onClick={saveForLater} className='admin-goal-set-button'>Save for Later</button>
                <button onClick={skipStep} className='admin-goal-set-button'>Skip</button>
                <button onClick={nextStep} className='admin-goal-set-button'>Next</button>
            </div>
        </div>
    );
}

export default Step2;