import React from 'react';
import { Link } from 'react-router-dom';
import './Styles/LogInSelection.css';

const LogInSelection = () => {
    return (
        <div className='logInSelection-body'>
            <h1>Log In As</h1>
            <div className="container">
                <div className="box">
                    <Link to="/AdminPortal" className="box-link">
                        <h2>Admin</h2>
                        <p>Access admin features and settings</p>
                    </Link>
                </div>
                <div className="box">
                    <Link to="/ManagerPortal" className="box-link">
                        <h2>Manager</h2>
                        <p>Manage team and view reports</p>
                    </Link>
                </div>
                <div className="box">
                    <Link to="/EmployeePortal" className="box-link">
                        <h2>Employee</h2>
                        <p>View tasks and submit reports</p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LogInSelection;
