import React from 'react';

function ManagerStep2({ teamGoals, setTeamGoals, prevStep, nextStep, saveForLater, skipStep, textareaRef }) {
    const addTeamGoal = () => {
        if (teamGoals.length < 5) {
            setTeamGoals([...teamGoals, { text: '', confirmed: false }]);
        }
    };

    const handleTeamGoalChange = (index, value) => {
        const newTeamGoals = [...teamGoals];
        newTeamGoals[index].text = value;
        setTeamGoals(newTeamGoals);
    };

    const toggleTeamGoalConfirmation = (index) => {
        const newTeamGoals = [...teamGoals];
        newTeamGoals[index].confirmed = !newTeamGoals[index].confirmed;
        setTeamGoals(newTeamGoals);
    };

    const deleteTeamGoal = (index) => {
        const newTeamGoals = teamGoals.filter((_, i) => i !== index);
        setTeamGoals(newTeamGoals);
    };

    return (
        <div className='manager-goal-set-container'>
            <h2 className='manager-goal-set-title'>Set Your Team Goals</h2>
            <p className='manager-goal-set-description'>Please enter up to five team-wide goals below. These will help personalize goals and make them more relevant to your team.</p>
            {teamGoals.map((teamGoal, index) => (
                <div key={index} className='manager-goal-set-flex-container'>
                    {teamGoal.confirmed ? (
                        <p className='manager-goal-set-confirmed'>{teamGoal.text}</p>
                    ) : (
                        <textarea
                            className='manager-goal-set-input'
                            placeholder='Enter a team goal here'
                            value={teamGoal.text}
                            onChange={(e) => handleTeamGoalChange(index, e.target.value)}
                            ref={textareaRef}
                        />
                    )}
                    <div className="manager-goal-set-button-wrapper">
                        <button onClick={() => toggleTeamGoalConfirmation(index)} className={`manager-goal-set-confirm-button ${teamGoal.confirmed ? 'manager-goal-set-edit-button' : ''}`}>
                            {teamGoal.confirmed ? 'Edit' : 'Confirm'}
                        </button>
                        <button onClick={() => deleteTeamGoal(index)} className='manager-goal-delete-button'>Delete</button>
                    </div>
                </div>
            ))}
            {teamGoals.length < 5 && (
                <button onClick={addTeamGoal} className='manager-goal-set-add-button'>Add Goal</button>
            )}
            <div className='manager-goal-set-button-container'>
                <button onClick={prevStep} className='manager-goal-set-button'>Back</button>
                <button onClick={saveForLater} className='manager-goal-set-button'>Save for Later</button>
                <button onClick={skipStep} className='manager-goal-set-button'>Skip</button>
                <button onClick={nextStep} className='manager-goal-set-button'>Next</button>
            </div>
        </div>
    );
}

export default ManagerStep2;