// src/Pages/ManagerPortal/TeamMembers.js
import React, { useState, useEffect } from 'react';
import { placeholderTeamMembers } from './placeholderData'; // Import placeholder data
import '../Styles/TeamMembers.css';

const TeamMembers = ({ managerId }) => {
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        // Simulate fetching data
        const fetchTeamMembers = () => {
            // For now, use placeholder data
            setTeamMembers(placeholderTeamMembers);
        };

        fetchTeamMembers();
    }, [managerId]);

    const handleBackLinkClick = () => {
        window.history.back();
    };

    return (
        <div className='team-members-body'>
            <p onClick={handleBackLinkClick} className="back-link">
                Back
            </p>
            <h1 className='team-members-header'>Your Team Members</h1>
            <div className="team-members">
                {teamMembers.map(member => (
                    <div key={member.id} className="team-member">
                        <h2>{member.name}</h2>
                        <p>{member.position}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamMembers;