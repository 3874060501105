import React from 'react';
import { useNavigate } from 'react-router-dom';

function ManagerGoalReview({ companyMission, goals, nextStep }) {
    const navigate = useNavigate();

    return (
        <div className='manager-goal-set-container'>
            <h2 className='manager-goal-review-title'>Review Company Mission and Goals</h2>
            <h3 className='manager-goal-review-mission'>Company Mission:</h3>
            <p className='manager-goal-review-text'>Try to incorporate the company mission into the goals you set for your team.</p>
            <p className='manager-goal-review-mission-text'>{companyMission}</p>
            <h3 className='manager-goal-review-goals'>Company Goals:</h3>
            <p className='manager-goal-review-text'>What team goals can you set to help your team achieve their personal and company goals?</p>
            <ul className='manager-goal-review-goal-list'>
                {goals.map((goal, index) => (
                    <li key={index} className={goal.confirmed ? 'goal-confirmed' : 'goal-pending'}>
                        {goal.text} {goal.confirmed ? '(Confirmed)' : '(Pending)'}
                    </li>
                ))}
            </ul>
            <div className='manager-goal-set-button-container'>
                <button className='manager-goal-set-button' onClick={() => navigate('/ManagerPortal')}>Back</button>
                <button className='manager-goal-set-button' onClick={nextStep}>Next</button>
            </div>
        </div>
    );
}

export default ManagerGoalReview;
