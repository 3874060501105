// src/components/SalesQuotePage.js
import React, { useState } from 'react';
import axios from 'axios';
import './Styles/SalesQuotePage.css'; // Import the CSS file

const SalesQuotePage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    phoneNumber: '',
    employeeCount: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('http://localhost:5000/submit-quote', formData)
      .then((response) => {
        console.log('SUCCESS!', response.data);
        alert('Your quote request has been saved successfully!');
      })
      .catch((error) => {
        console.error('FAILED...', error);
        alert('There was an error saving your request. Please try again.');
      });
  };

  return (
    <div className="sales-quote-container">
      <h1 className="sales-quote-header">Request a Sales Quote</h1>
      <p className="sales-quote-description">Please fill out the form below to receive a personalized quote.</p>
      <form className="sales-quote-form" onSubmit={handleSubmit}>
        <div>
          <label>First Name:</label>
          <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
        </div>
        <div>
          <label>Last Name:</label>
          <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div>
          <label>Company:</label>
          <input type="text" name="company" value={formData.company} onChange={handleChange} required />
        </div>
        <div>
          <label>Phone Number:</label>
          <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
        </div>
        <div>
          <label>Employee Count:</label>
          <input type="number" name="employeeCount" value={formData.employeeCount} onChange={handleChange} required />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default SalesQuotePage;