import React from 'react';
import PropTypes from 'prop-types';

function EmployeeStep2({ employeeGoals = [], setEmployeeGoals, prevStep, nextStep, saveForLater, textareaRef, userID }) {
    React.useEffect(() => {
        if (employeeGoals.length === 0) {
            setEmployeeGoals([{ text: '', confirmed: false, userID: '' }]);
        }
    }, [employeeGoals, setEmployeeGoals]);

    const addEmployeeGoal = () => {
        if (employeeGoals.length < 5) {
            setEmployeeGoals([...employeeGoals, { text: '', confirmed: false, userID: userID }]);
        }
    };

    const handleEmployeeGoalChange = (index, value) => {
        const newEmployeeGoals = [...employeeGoals];
        newEmployeeGoals[index].text = value;
        setEmployeeGoals(newEmployeeGoals);
    };

    const toggleEmployeeGoalConfirmation = (index) => {
        const newEmployeeGoals = [...employeeGoals];
        newEmployeeGoals[index].confirmed = !newEmployeeGoals[index].confirmed;
        setEmployeeGoals(newEmployeeGoals);
    };

    const deleteEmployeeGoal = (index) => {
        const newEmployeeGoals = employeeGoals.filter((_, i) => i !== index);
        setEmployeeGoals(newEmployeeGoals);
    };

    return (
        <div className='employee-goal-set-container'>
            <h2 className='employee-goal-set-title'>Set Your Personal Goals</h2>
            <p className='employee-goal-set-description'>Please enter up to five personal goals below. These will help personalize your development and make them more relevant to your career path.</p>
            {employeeGoals.map((employeeGoal, index) => (
                <div key={index} className='employee-goal-set-flex-container'>
                    {employeeGoal.confirmed ? (
                        <p className='employee-goal-set-confirmed'>{employeeGoal.text}</p>
                    ) : (
                        <textarea
                            className='employee-goal-set-input'
                            placeholder='Enter a personal goal here'
                            value={employeeGoal.text}
                            onChange={(e) => handleEmployeeGoalChange(index, e.target.value)}
                            ref={textareaRef}
                        />
                    )}
                    <div className="employee-goal-set-button-wrapper">
                        {!employeeGoal.confirmed && (
                            <button className='employee-goal-set-ai-button'>Astellyst AI</button>
                        )}
                        <button onClick={() => toggleEmployeeGoalConfirmation(index)} className={`employee-goal-set-confirm-button ${employeeGoal.confirmed ? 'employee-goal-set-edit-button' : ''}`}>
                            {employeeGoal.confirmed ? 'Edit' : 'Confirm'}
                        </button>
                        <button onClick={() => deleteEmployeeGoal(index)} className='employee-goal-delete-button'>Delete</button>
                    </div>
                </div>
            ))}
            {employeeGoals.length < 5 && (
                <button onClick={addEmployeeGoal} className='employee-goal-set-add-button'>Add Goal</button>
            )}
            <div className='employee-goal-set-button-container'>
                <button onClick={prevStep} className='employee-goal-set-button'>Back</button>
                <button onClick={saveForLater} className='employee-goal-set-button'>Save for Later</button>
                <button onClick={nextStep} className='employee-goal-set-button'>Next</button>
            </div>
        </div>
    );
}

EmployeeStep2.propTypes = {
    employeeGoals: PropTypes.array.isRequired,
    setEmployeeGoals: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    saveForLater: PropTypes.func.isRequired,
    textareaRef: PropTypes.object,
    userID: PropTypes.string.isRequired,
};

export default EmployeeStep2;
