import React, { useState, useRef, useEffect } from 'react';
import EmployeeStep1 from './EmployeeStep1'; // Import the first step
import EmployeeStep2 from './EmployeeStep2'; // Placeholder for future steps
import EmployeeStep3 from './EmployeeStep3'; // Placeholder for future steps
import '../Styles/EmployeeMaster.css';
import { attachAutoExpand, detachAutoExpand } from '../../../Util/autoExpand';
import { useLocation } from 'react-router-dom';

function EmployeeMaster({ companyMission, goals = [], teamGoals = [] }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialStep = parseInt(queryParams.get('step'), 10) || 0;

    const [currentStep, setCurrentStep] = useState(initialStep);
    const [employeeGoals, setEmployeeGoals] = useState([]);
    const textareaRef = useRef(null);

    const nextStep = () => setCurrentStep(prev => prev + 1);
    const prevStep = () => setCurrentStep(prev => prev - 1);

    const saveForLater = () => {
        const dataToSave = {
            userId: "",
            data: {
                employeeGoals,
            },
        };

        fetch('/api/employee-goals/save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSave),
        })
        .then(response => response.json())
        .then(data => {
            alert('Progress saved successfully!');
        })
        .catch(error => {
            console.error('Error saving progress:', error);
            alert('Failed to save progress. Please try again.');
        });
    };

    const submitData = () => {
        const dataToSubmit = {
            userId: "",
            data: {
                employeeGoals,
            },
        };

        fetch('/api/employee-goals/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSubmit),
        })
        .then(response => response.json())
        .then(data => {
            alert('Data submitted successfully!');
        })
        .catch(error => {
            console.error('Error submitting data:', error);
            alert('Failed to submit data. Please try again.');
        });
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        attachAutoExpand(textarea);

        return () => {
            detachAutoExpand(textarea);
        };
    }, []);

    return (
        <div className='employee-goal-set-body'>
            {currentStep === 0 && (
                <EmployeeStep1
                    companyMission={companyMission}
                    goals={goals}
                    teamGoals={teamGoals}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    textareaRef={textareaRef}
                />
            )}
            {currentStep === 1 && (
                <EmployeeStep2
                    employeeGoals={employeeGoals}
                    setEmployeeGoals={setEmployeeGoals}
                    textareaRef={textareaRef}
                    prevStep={prevStep}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    userID=""
                />
            )}
            {currentStep === 2 && (
                <EmployeeStep3
                    employeeGoals={employeeGoals}
                    setEmployeeGoals={setEmployeeGoals}
                    prevStep={prevStep}
                    saveForLater={saveForLater}
                    submitData={submitData}
                />
            )}
        </div>
    );
}

export default EmployeeMaster;
