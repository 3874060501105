import React, { useState } from 'react';
import '../Styles/EmployeePortal.css'; // Updated CSS import
import { Link } from 'react-router-dom';

const EmployeePortal = () => {
    const [activeTab, setActiveTab] = useState('set-goals');

    const renderContent = () => {
        switch (activeTab) {
            case 'set-goals':
                return (
                    <div>
                        <h2>Set Goals</h2>
                        <p>Define your personal goals.</p>
                        <div className='goal-setting-button-container'>
                            <Link to="/EmployeeMaster">
                                <button className='goal-setting-button'>Set Goals</button>
                            </Link>
                            <Link to="/EmployeeMaster?step=2">
                                <button className='goal-setting-button'>Review Goals</button>
                            </Link>
                        </div>
                    </div>
                );
            case    'your-progress':   
                return (
                    <div>
                        <h2>Your Progress</h2>
                        <p>View your progress on your goals.</p>
                        <Link to="/ProgressPage">
                            <button className='goal-setting-button'>View Progress</button>
                        </Link>
                    </div>
                );
            default:
                return <div>Please select a tab.</div>;
        }
    };

    return (
        <div className="employee-portal"> {/* Updated class name */}
            <div className="employee-portal-sidebar">
                <button className="employee-portal-sidebar-button" onClick={() => setActiveTab('set-goals')}>Set Goals</button>
                <button className="employee-portal-sidebar-button" onClick={() => setActiveTab('your-progress')}>Your Progress</button>
            </div>
            <div className="employee-portal-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default EmployeePortal;
