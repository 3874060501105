import React, { useState } from 'react';
import Cookies from 'js-cookie'; // Importing js-cookie for cookie management
import './Styles/LogInPage.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function LogInPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    const handleLogin = (e) => {
        e.preventDefault();
        // Handle login logic here (e.g., API call)
        // For now, we'll just save the email in cookies
        Cookies.set('userEmail', email, { expires: 7 }); // Save email for 7 days
        alert('Logged in with email: ' + email);
    };

    const handleSSO = () => {
        // Handle SSO login logic here
        alert('Redirecting to SSO login...');
    };

    const handleForgotPassword = () => {
        // Redirect to ForgotPassword component
        navigate('/ForgotPassword'); // Update to use navigate
    };

    return (
        <div className="login-body">
            
            <form onSubmit={handleLogin}>
                <div className="login-box">
                    <h1>Log In</h1>
                    <p>Sign in to your Astellyst account.</p>
                    <div className="login-container">
                        <input 
                            placeholder="Email"
                            className="login-input"
                            type="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="login-container">
                        <input 
                            placeholder="Password"
                            className="login-input"
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="forgot-password-container">
                        <p className="forgot-password-link" onClick={handleForgotPassword}>
                            Forgot Password?
                        </p>
                    </div>
                    <div className="login-button-container">    
                        <button className="login-button" type="submit">Log In</button>
                    </div>
                    <div className="or-container">
                        <hr className="or-line" />
                        <span>or</span>
                        <hr className="or-line" />
                    </div>
                    <div className="login-sso-button-container">
                        <button className="login-sso-button" onClick={handleSSO}>Log In with SSO</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default LogInPage;
