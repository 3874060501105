import React, { useState, useEffect } from 'react';

function Step3({ teamGoals, prevStep, submitData, saveForLater, setTeamGoals }) {
    const [isEditingGoals, setIsEditingGoals] = useState(false);
    const [editedGoals, setEditedGoals] = useState([]);

    useEffect(() => {
        // Initialize editedGoals with at least one empty goal if there are no confirmed goals
        if (teamGoals.length === 0 || teamGoals.every(goal => !goal.text.trim())) {
            setEditedGoals(['']);
        } else {
            setEditedGoals(teamGoals.map(goal => goal.text));
        }
    }, [teamGoals]);

    const toggleEditGoals = () => {
        if (isEditingGoals) {
            const nonEmptyGoals = editedGoals.filter(goalText => goalText.trim() !== '');
            setEditedGoals(nonEmptyGoals.length > 0 ? nonEmptyGoals : ['']);
            setTeamGoals(nonEmptyGoals.map(goalText => ({ text: goalText, confirmed: goalText !== '' })));
        }
        setIsEditingGoals(!isEditingGoals);
    };

    const addGoal = () => {
        if (editedGoals.length < 5) {
            setEditedGoals([...editedGoals, '']); // Add a new empty goal
        }
    };

    const deleteGoal = (index) => {
        if (editedGoals.length > 1) { // Only allow deletion if more than one goal exists
            const newGoals = [...editedGoals];
            newGoals.splice(index, 1); // Remove the goal at the specified index
            setEditedGoals(newGoals);
        }
    };

    return (
        <div className='manager-goal-set-container'>
            <h2>Review Your Information</h2>
            <div className='manager-goal-set-review-container'>
                <h3>Team Goals</h3>
                <div className='manager-goal-set-flex-container'>
                    {isEditingGoals ? (
                        <ul>
                            {editedGoals.map((goal, index) => (
                                <li className='manager-goal-set-review-list-edit' key={index}>
                                    <textarea 
                                        className='manager-goal-set-input'
                                        value={goal} 
                                        onChange={(e) => {
                                            const newGoals = [...editedGoals];
                                            newGoals[index] = e.target.value;
                                            setEditedGoals(newGoals);
                                        }} 
                                    />
                                    {editedGoals.length > 1 && (
                                        <button 
                                            onClick={() => deleteGoal(index)} 
                                            className='manager-goal-delete-button'
                                        >
                                            Delete
                                        </button>
                                    )}
                                </li>
                            ))}
                            <div className='manager-goal-set-button-review-wrapper'>
                                {editedGoals.length < 5 && (
                                    <button onClick={addGoal} className='manager-goal-set-add-button'>Add Goal</button>
                                )}
                                <button onClick={toggleEditGoals} className='manager-goal-set-button'>Done Editing</button>
                            </div>
                        </ul>
                    ) : (
                        <ul className='manager-goal-set-review-list-text'>
                            {teamGoals.length > 0 && teamGoals.filter(goal => goal.confirmed && goal.text).length > 0 ? (
                                teamGoals.filter(goal => goal.confirmed && goal.text).map((goal, index) => (
                                    <li className='manager-goal-set-list-item' key={index}>{goal.text}</li>
                                ))
                            ) : (
                                <p className='manager-goal-set-review-text'>No team goals provided.</p>
                            )}
                        </ul>
                    )}
                    {!isEditingGoals && (
                        <button onClick={toggleEditGoals} className='manager-goal-set-button'>Edit</button>
                    )}
                </div>
            </div>
            <div className='manager-goal-set-button-container'>
                <button onClick={prevStep} className='manager-goal-set-button'>Back</button>
                <button onClick={saveForLater} className='manager-goal-set-button'>Save for Later</button>
                <button onClick={submitData} className='manager-goal-set-submit-button'>Submit</button>
            </div>
        </div>
    );
}

export default Step3;